/* eslint-disable */
/* tslint-disable */
// @ts-nocheck
// *******************************************************
// *******************************************************
//
// GENERATED FILE, DO NOT MODIFY
//
// Made by Victor Garcia ®
//
// https://github.com/victorgarciaesgi
// *******************************************************
// *******************************************************
// 💙

export interface IQuery {
  _empty?: string;
  me?: IUser;
  user: IUser;
  usersConnection: IUserConnection;
  requestResetUserPassword?: boolean;
  requestResetUserPasswordWithEmail?: boolean;
  nurseriesConnection: INurseryConnectionWithRevenues;
  nurseriesSearch: INurseryConnection;
  nursery?: INursery;
  nurseryPlanning: INurseryPlanning;
  nurseryKeyFigures: INurseryKeyFigures;
  nurseries?: INursery[];
  dspNurseryZipCodes?: IDspNurseryZipCode[];
  bansConnection: ICustomersConnection;
  document: IDocument;
  getDocumentUrl: string;
  child: IChild;
  waitingChild: IChildConnection;
  customerMe: ICustomer;
  customer: ICustomerWithRegistration;
  customersConnection: IRegisteredConnection;
  exportCustomers: string;
  reservationsConnection: IReservationConnection;
  getAllReservations: IReservationConnection;
  reservation: IReservation;
  dashboard: IDashboard;
  communityNurseryDashboard: IDashboardCommunity;
  nurseryConfig: INurseryConfigWithDefaultPrice;
  registrationsConnection: IRegistrationsConnection;
  registration: IRegistration;
  mailsConnection: IMailConnection;
  availability: IAvailability;
  availabilityMultiple?: IAvailability[];
  nurseriesAvailable?: IAvailabilityConnection;
  nurseriesAvailableMultiple?: IMultipleAvailabilityConnection;
  getMoneticoData: IMonetico;
  refundReservation: boolean;
  exportPayments?: string;
  keyStatistics: IKeyStatistics;
  newNurseries: INurseryConnection;
  capacityStatistics: ICapacityStatistics[];
  exportKeyStatistics: string;
  exportNewNurseries: string;
  exportCapacityStatistics: string;
  exportUsersLogsAndData: string;
  exportReservationsNurseries?: string;
  familyCode?: IFamilyCode;
  billingsConnection: IBillingsConnection;
  getBillingUrl: string;
  loginlog: ILoginlog;
  test?: boolean;
  effortRates?: IEffortRate[];
  communityNurseryTemplateAvailabilities?: ICommunityNurseryTemplateAvailability[];
  communityNurseryZipCodes?: ICommunityNurseryZipCode[];
  getPublicHolidays?: string[];
  getZipCodes?: string;
  childAdaptationForm?: IChildAdaptationForm[];
}

export interface IUser {
  id: string;
  email: string;
  password?: string;
  firstName: string;
  lastName: string;
  slug: string;
  role: IRole;
  nurseries: INursery[];
  loginlogs?: ILoginlog[];
  active?: boolean;
  createdAt: Date;
  updatedAt: Date;
}

export interface INursery {
  id: string;
  analyticCode: string;
  name: string;
  slug: string;
  lat?: number;
  lon?: number;
  address?: string;
  zipCode?: string;
  city?: string;
  citySlug?: string;
  phone?: string;
  reservationPhone?: string;
  description?: string;
  nurseryPlus?: string;
  information?: string;
  pedagogy?: string;
  tagInternationnal?: string;
  tagGraine?: string;
  tagBio?: string;
  type: INurseryType;
  tags?: INurseryTag[];
  formularId?: string;
  summerCampStartDate?: Date;
  summerCampEndDate?: Date;
  sections?: ISection[];
  schedule: ISchedule;
  visit: IVisit;
  pictures: IPicture[];
  responsibleName?: string;
  responsibleEmail?: string;
  mikadoId: string;
  registrations?: IRegistration[];
  closingDates?: IClosingDate[];
  reservations?: IReservation[];
  communityNurseryZipCodes?: ICommunityNurseryZipCode[];
  dspNurseryZipCodes?: IDspNurseryZipCode[];
  cegidCode?: string;
  active: boolean;
  oneDayBeforeReservation: boolean;
  onlyPhoneReservationEnable: boolean;
  communityNursery: boolean;
  dspNursery: boolean;
  tarificationHours: boolean;
  visitable: boolean;
  createdAt: Date;
  updatedAt: Date;
}

export interface ISection {
  id: string;
  code: string;
  name: string;
  ageMin: number;
  ageMax: number;
  capacity: number;
  availabilities: IAvailability[];
  nursery: INursery;
  reservations?: IReservation[];
  polluxSectionId: number;
  nextThreeMonthsAvailabilities: number;
  createdAt: Date;
  updatedAt: Date;
}

export interface IAvailability {
  id: string;
  compoundKey: string;
  section: ISection;
  sections: IAvailabilitySection[];
  remainingPlaces: number;
  date: Date;
  type: IReservationType;
  createdAt: Date;
  updatedAt: Date;
}

export interface IAvailabilitySection {
  id: string;
  name: string;
  capacity: number;
  remainingPlaces: number;
  date: Date;
  availability: IAvailability;
  createdAt: Date;
  updatedAt: Date;
}

export interface IReservation {
  id: string;
  status: IReservationStatus;
  statusReason?: IRejectReason;
  reasonComment?: string;
  refusedBy?: IRole;
  child: IChild;
  nursery: INursery;
  section: ISection;
  customer?: ICustomer;
  price?: number;
  extraPrice?: number;
  psuPrice?: number;
  fromHours?: number;
  toHours?: number;
  type: IReservationType;
  date: Date;
  validationDate?: Date;
  companyCode?: string;
  receipts?: IReceipt[];
  isSummerCampDate: boolean;
  isTarificationHours: boolean;
  createdAt: Date;
  updatedAt: Date;
}

export interface IChild {
  id: string;
  firstName: string;
  lastName: string;
  picture?: IPicture;
  documents: IDocument[];
  allergies: IAllergy[];
  notes?: string;
  reservations?: IReservation[];
  parent: ICustomer;
  registrations?: IRegistration[];
  birthDate: Date;
  validationDate?: Date;
  createdAt: Date;
  updatedAt: Date;
}

export interface IPicture {
  id: string;
  url: string;
  createdAt: Date;
  updatedAt: Date;
}

export interface IDocument {
  id: string;
  uuid: string;
  type: IDocumentType;
  status: IDocumentStatus;
  format: IDocumentFormat;
  extension: string;
  expirationDate?: Date;
  reason?: IDocumentRejectedReason;
  comment?: string;
  required: boolean;
  child?: IChild;
  parent?: ICustomer;
  createdAt: Date;
  updatedAt: Date;
}

export interface ICustomer {
  id: string;
  slug: string;
  email: string;
  gender: ICustomerGender;
  firstName: string;
  lastName: string;
  address?: string;
  zipCode?: string;
  phone?: string;
  city?: string;
  password: string;
  logincustomerlogs?: ILoginCustomerlog[];
  lang: ILangType;
  children: IChild[];
  reservations: IReservation[];
  documents: IDocument[];
  cafDocuments: ICafDocument[];
  picture?: IPicture;
  bans: IBan[];
  price?: number;
  isBanned: boolean;
  banReason?: IBanReason;
  banComment?: string;
  active: boolean;
  newsletter: boolean;
  familyCode?: IFamilyCode[];
  isJobSeeker: boolean;
  isResident: boolean;
  createdAt: Date;
  updatedAt: Date;
  companyFirst?: string;
  companySecond?: string;
  companyCode?: string;
}

export interface ILoginCustomerlog {
  id: string;
  customer: ICustomer;
  createdAt: Date;
  updatedAt: Date;
}

export interface ICafDocument {
  id: string;
  uuid: string;
  title: string;
  format: IDocumentFormat;
  extension: string;
  parent?: ICustomer;
  createdAt: Date;
  updatedAt: Date;
}

export interface IBan {
  id: string;
  nursery: INursery;
  customer: ICustomer;
  reason: IBanReason;
  description?: string;
  createdAt: Date;
  updatedAt: Date;
}

export interface IFamilyCode {
  id: string;
  customer: ICustomer;
  nursery: INursery;
  codeFamily: string;
  createdAt: Date;
  updatedAt: Date;
}

export interface IRegistration {
  id: string;
  child: IChild;
  nursery: INursery;
  status: IRegistrationStatus;
  registrationDate?: Date;
  reservation?: IReservation;
  welcoming?: IWelcoming;
  createdAt: Date;
  updatedAt: Date;
}

export interface IWelcoming {
  id: string;
  date: Date;
  nursery: INursery;
  child: IChild;
  status: IWelcomingStatus;
  registration: IRegistration;
  createdAt: Date;
  updatedAt: Date;
}

export interface IReceipt {
  id: string;
  reference: string;
  amount: number;
  mac: string;
  date: string;
  customer: ICustomer;
  reservation?: IReservation;
  reservations?: IReservation[];
  status: IReceiptStatus;
  authNumber?: string;
  isCesuActive: boolean;
  eCesuAmount?: number;
  eCesuCode?: string;
  createdAt: Date;
  updatedAt: Date;
}

export interface ISchedule {
  id: string;
  morningStartRangeFirst: string;
  morningStartRangeSecond: string;
  morningEndRangeFirst: string;
  morningEndRangeSecond: string;
  afternoonStartRangeFirst: string;
  afternoonStartRangeSecond: string;
  afternoonEndRangeFirst: string;
  afternoonEndRangeSecond: string;
  openingHours: string;
  closingHours: string;
  polluxOpeningHours?: string;
  polluxClosingHours?: string;
}

export interface IVisit {
  id: string;
  weekDay: number;
  start: string;
  end: string;
}

export interface IClosingDate {
  id: string;
  closingDateStart: Date;
  closingDateEnd: Date;
  polluxClosingDateId: number;
  nursery?: INursery;
}

export interface ICommunityNurseryZipCode {
  id: string;
  nursery: INursery;
  zipCode: string;
  createdAt: Date;
  updatedAt: Date;
}

export interface IDspNurseryZipCode {
  id: string;
  nursery: INursery;
  zipCode: string;
  createdAt: Date;
  updatedAt: Date;
}

export interface ILoginlog {
  id: string;
  user: IUser;
  createdAt: Date;
  updatedAt: Date;
}

export interface IUserWhereUniqueInput {
  id?: string;
}

export interface IUserConnectionWhereInput {
  role: IRole;
  searchQuery?: string;
}

export interface IUserConnection {
  pageInfo: IPageInfo;
  aggregate: IAggregate;
  edges: IUserEdge[];
}

export interface IPageInfo {
  hasNextPage: boolean;
  hasPreviousPage: boolean;
  startCursor?: string;
  endCursor?: string;
  totalPage?: number;
}

export interface IAggregate {
  count: number;
}

export interface IUserEdge {
  node: IUser;
}

export interface INurseryConnectionWhereInput {
  search?: string;
  active?: boolean;
  city?: string;
  orderBy?: INurseryConnectionFilterEnum;
  isSummer?: boolean;
}

export interface INurseryConnectionWithRevenues {
  pageInfo: IPageInfo;
  aggregate: IAggregate;
  edges: INurseryEdgeWithRevenues[];
}

export interface INurseryEdgeWithRevenues {
  node: INurseryWithRevenues;
}

export interface INurseryWithRevenues {
  id: string;
  name: string;
  analyticCode: string;
  pendingReservation: number;
  pendingRegistration: number;
  processed: number;
  revenues: number;
  active: boolean;
}

export interface INurserySearchWhereInput {
  search?: string;
  lat?: number;
  lon?: number;
  original?: boolean;
  tags?: INurseryTag[];
  isSummer?: boolean;
}

export interface INurseryConnection {
  pageInfo: IPageInfo;
  aggregate: IAggregate;
  edges: INurseryEdge[];
}

export interface INurseryEdge {
  node: INursery;
}

export interface INurseryUniqueInput {
  id?: string;
  analyticCode?: string;
  name?: string;
}

export interface INurseryPlanning {
  reservations: IReservation[];
  availabilities: IAvailability[];
  registrations: IRegistration[];
  welcomings?: IWelcomingExtended[];
  communityNurseryAvailabilities: ICommunityNurseryAvailability[];
}

export interface IWelcomingExtended {
  id: string;
  date: Date;
  child: IChildLight;
  status: IWelcomingStatus;
  createdAt: Date;
  updatedAt: Date;
}

export interface IChildLight {
  id: string;
  firstName: string;
  lastName: string;
  parent: IParentLight;
}

export interface IParentLight {
  id: string;
  firstName: string;
  lastName: string;
}

export interface ICommunityNurseryAvailability {
  id: string;
  nursery: INursery;
  analyticCode: string;
  type: IReservationType;
  code: string;
  capacity: number;
  remainingPlaces: number;
  date: Date;
  createdAt: Date;
  updatedAt: Date;
}

export interface INurseryKeyFigures {
  availabilities?: number;
  revenues?: number;
  signUps?: number;
}

export interface IBansConnectionInput {
  search?: string;
  nursery?: IWhereUniqueInput;
}

export interface IWhereUniqueInput {
  id: string;
}

export interface ICustomersConnection {
  pageInfo: IPageInfo;
  aggregate: IAggregate;
  edges: ICustomerEdge[];
}

export interface ICustomerEdge {
  node: ICustomerExtended;
  cursor?: string;
}

export interface ICustomerExtended {
  id: string;
  email: string;
  firstName: string;
  lastName: string;
  address?: string;
  zipCode?: string;
  phone?: string;
  password: string;
  children: IChild[];
  reservations?: IReservation[];
  nurseryNumber: number;
  documents: IDocument[];
  picture?: IPicture;
  bans: IBan[];
  price?: number;
  isBanned?: boolean;
  isJobSeeker?: boolean;
  isResident?: boolean;
  createdAt: Date;
  updatedAt: Date;
}

export interface IWhereWaitingChild {
  search?: string;
}

export interface IChildConnection {
  pageInfo: IPageInfo;
  aggregate: IAggregate;
  edges: IChildEdge[];
}

export interface IChildEdge {
  node: IChild;
}

export interface ICustomerWithRegistration {
  id: string;
  email: string;
  firstName: string;
  lastName: string;
  address?: string;
  zipCode?: string;
  city?: string;
  phone?: string;
  password: string;
  children: IChild[];
  registrations?: IRegistration[];
  nurseryNumber: number;
  documents: IDocument[];
  cafDocuments: ICafDocument[];
  picture?: IPicture;
  bans: IBan[];
  price?: number;
  isBanned?: boolean;
  isJobSeeker?: boolean;
  isResident?: boolean;
  banReason?: IBanReason;
  banComment?: string;
  createdAt: Date;
  updatedAt: Date;
  companyFirst?: string;
  companySecond?: string;
  companyCode?: string;
}

export interface ICustomersExportWhereInput {
  search?: string;
  orderBy?: ICustomersConnectionOrderEnum;
  filterBy?: ICustomersConnectionFilterEnum;
  start?: Date;
  end?: Date;
}

export interface IRegisteredConnection {
  pageInfo: IPageInfo;
  aggregate: IAggregate;
  edges: IRegisteredEdge[];
}

export interface IRegisteredEdge {
  node: IRegistered;
  cursor: string;
}

export interface IRegistered {
  id: string;
  email: string;
  firstName: string;
  lastName: string;
  address?: string;
  zipCode?: string;
  phone?: string;
  password: string;
  nurseryNumber: number;
  price?: number;
  isBanned?: boolean;
  isJobSeeker?: boolean;
  isResident?: boolean;
  pending?: number;
  validated?: number;
  documentStatus?: ICustomersConnectionFilterEnum;
  createdAt: Date;
  updatedAt: Date;
}

export interface IReservationConnectionWhereInput {
  nursery?: INurseryWhereUniqueInput;
  customer?: ICustomerWhereUniqueInput;
  startDate?: Date;
  endDate?: Date;
  status?: IReservationStatus;
  noRegistration?: boolean;
}

export interface INurseryWhereUniqueInput {
  id: string;
}

export interface ICustomerWhereUniqueInput {
  id?: string;
  email?: string;
}

export interface IReservationConnection {
  pageInfo: IPageInfo;
  aggregate: IAggregate;
  edges: IReservationEdge[];
}

export interface IReservationEdge {
  node: IReservation;
}

export interface IgetAllReservationsWhereInput {
  nursery?: INurseryWhereUniqueInput;
  startDate?: Date;
  endDate?: Date;
  customer?: ICustomerWhereUniqueInput;
  status?: IReservationStatus;
}

export interface IDashboard {
  processedRegistrations?: number;
  processedReservations?: number;
  pendingRegistrations?: number;
  pendingReservations?: number;
  nurseryNumber?: number;
  recentNurseries?: number;
  availabilities?: number;
  revenues?: number;
  validatedRegistrations?: number;
}

export interface IDashboardCommunity {
  processedRegistrations?: number;
  processedReservations?: number;
  pendingRegistrations?: number;
  pendingReservations?: number;
  nurseryNumber?: number;
  occupancyRate?: number;
  availabilities?: number;
  revenues?: number;
  validatedRegistrations?: number;
}

export interface INurseryConfigWithDefaultPrice {
  id: string;
  price: number;
  defaultPrice: number;
}

export interface IRegistrationWhereInput {
  nursery: INurseryWhereUniqueInput;
  status?: IRegistrationStatus;
}

export interface IRegistrationsConnection {
  pageInfo: IPageInfo;
  aggregate: IAggregate;
  edges?: IRegistrationEdge[];
}

export interface IRegistrationEdge {
  node: IRegistration;
}

export interface IRegistrationWhereUniqueInput {
  id: string;
}

export interface IMailWhereInput {
  search?: string;
  type?: IMailType;
  lang?: ILangType;
}

export interface IMailConnection {
  pageInfo: IPageInfo;
  aggregate: IAggregate;
  edges: IMailEdge[];
}

export interface IMailEdge {
  node: IMail;
}

export interface IMail {
  id: string;
  slug: string;
  name: string;
  object: string;
  content: string;
  type: IMailType;
  lang: ILangType;
  variables: IVariable[];
  createdAt: Date;
  updatedAt: Date;
}

export interface IVariable {
  id: string;
  name: string;
  value: string;
}

export interface IAvailabilityMultipleInput {
  id?: string[];
}

export interface IAvailabilitiesConnectionInput {
  lat?: number;
  lon?: number;
  date: Date;
  type: IReservationType;
  tags?: INurseryTag[];
  child?: IUniqueChildInput;
  nursery?: IWhereUniqueInput;
  more?: boolean;
}

export interface IUniqueChildInput {
  id: string;
}

export interface IAvailabilityConnection {
  pageInfo: IPageInfo;
  aggregate: IAggregate;
  edges?: IAvailabilityEdge[];
}

export interface IAvailabilityEdge {
  node: IAvailability;
}

export interface IAvailabilitiesMultipleConnectionInput {
  lat?: number;
  lon?: number;
  date?: Date[];
  type: IReservationType;
  tags?: INurseryTag[];
  child?: IUniqueChildInput;
  nursery?: IWhereUniqueInput;
  more?: boolean;
}

export interface IMultipleAvailabilityConnection {
  pageInfo: IPageInfo;
  aggregate: IAggregate;
  edges?: IAvailabilityEdge[];
  unavailabilitiesReason?: IUnavailabilityReason[];
}

export interface IUnavailabilityReason {
  date: Date;
  reason?: string;
}

export interface IReservationWhereMultiInput {
  ids?: string[];
}

export interface IMonetico {
  reference: string;
  amount: number;
  host: string;
  form: string;
}

export interface IReservationWhereUniqueInput {
  id: string;
}

export interface IKeyStatistics {
  newNurseries: IMonthStatistics;
  nurseriesWithAvailabilities?: IMonthStatistics;
  availabilities?: IMonthStatistics;
  registrationAccepted?: IMonthStatistics;
  reservationAccepted?: IMonthStatistics;
  reservationCancelled?: IMonthStatistics;
  reservationRefused?: IMonthStatistics;
  revenues?: IMonthStatisticsFloat;
  revenuesPSU?: IMonthStatisticsFloat;
  revenuesPAJE?: IMonthStatisticsFloat;
  revenuesCancelled?: IMonthStatisticsFloat;
}

export interface IMonthStatistics {
  average: number;
  m1: number;
  m2: number;
  m3: number;
  m4: number;
  m5: number;
  m6: number;
  current: number;
}

export interface IMonthStatisticsFloat {
  average: number;
  m1: number;
  m2: number;
  m3: number;
  m4: number;
  m5: number;
  m6: number;
  current: number;
}

export interface INurseriesConnectionWhereInput {
  start: Date;
  end: Date;
}

export interface ICapacityWhereInput {
  start: Date;
  end: Date;
  nurseries?: string[];
}

export interface ICapacityStatistics {
  name: string;
  totalCapacity: number;
  mikadoSlot: number;
  availableSlot: number;
  reservedSlot: number;
  remainingSlot: number;
}

export interface ILogsWhereInput {
  start: Date;
  end: Date;
}

export interface IExportReservationsWhereInput {
  start?: Date;
  end?: Date;
  nurseries?: string[];
}

export interface IFamilyCodeWhereInput {
  customer: ICustomerWhereUniqueInput;
  nursery: INurseryWhereUniqueInput;
}

export interface IBillingConnectionWhereInput {
  customer: ICustomerWhereUniqueInput;
}

export interface IBillingsConnection {
  pageInfo: IPageInfo;
  aggregate: IAggregate;
  edges: IBillingEdge[];
}

export interface IBillingEdge {
  node: IBilling;
}

export interface IBilling {
  id: string;
  name: string;
  date: Date;
  customer: ICustomer;
  uuid: string;
  extension: string;
  createdAt: Date;
  updatedAt: Date;
}

export interface ILoginLogUniqueInput {
  id: string;
}

export interface IEffortRate {
  id: string;
  numberOfChildren?: number;
  rate1?: number;
  rate2?: number;
  rate3?: number;
}

export interface ICommunityNurseryTemplateAvailability {
  id: string;
  nursery: INursery;
  analyticCode: string;
  type: IReservationType;
  code: string;
  capacity: number;
  remainingPlaces: number;
  day: number;
  createdAt: Date;
  updatedAt: Date;
}

export interface IChildAdaptationFormInput {
  parent?: string;
  child?: string;
}

export interface IChildAdaptationForm {
  id: string;
  parent: ICustomer;
  child: IChild;
  foodRestriction: boolean;
  foodRestrictionDescription?: string;
  breastfed: IBreastfedType;
  hygiene: IHygieneType;
  childAdaptationFormMotorSkills?: IChildAdaptationFormMotorSkills[];
  morningSleepTime?: number;
  afternoonSleepTime?: number;
  allergy: boolean;
  allergyDescription?: string;
  comment?: string;
  createdAt: Date;
  updatedAt: Date;
}

export interface IChildAdaptationFormMotorSkills {
  id: string;
  childAdaptationForm: IChildAdaptationForm;
  motorSkills: IMotorSkillsType;
  createdAt: Date;
  updatedAt: Date;
}

export interface IMutation {
  uploadPicture: IPicture;
  deletePicture: boolean;
  login?: IAuthPayload;
  resetUserPassword?: boolean;
  updateUser?: IUser;
  activateUser: IAuthPayload;
  deleteUser?: boolean;
  createUser?: IUser;
  updateNursery?: INursery;
  contactUs: boolean;
  updateDspNurseryZipCodes: boolean;
  createBan: IBan;
  deleteBan: boolean;
  createDocument: IDocument;
  updateDocument: IDocument;
  updateDocumentCustomer: IDocument;
  uploadDocument: IDocument;
  uploadCafDocument: ICafDocument;
  deleteCafDocument?: boolean;
  createChild: IChild;
  updateChild: IChild;
  loginCustomer: ICustomerWithToken;
  updateCustomer: ICustomer;
  banCustomer: ICustomer;
  register: ICustomerWithToken;
  resetPassword: boolean;
  updatePassword: boolean;
  disableAccount: boolean;
  deleteAccount: boolean;
  updateCustomerPassword: ICustomer;
  updateCustomerLang?: boolean;
  checkCompanyCode?: boolean;
  createReservation: IReservation[];
  updateReservation?: IReservation;
  cancelReservation: IReservation[];
  updateNurseryConfig: INurseryConfig;
  updateRegistration: IRegistration;
  createRegistration: IRegistrationCreation;
  updateMail: IMail;
  upsertFamilyCode: IFamilyCode;
  createBilling: IBilling;
  deleteBilling: boolean;
  executeCron?: boolean;
  updateCommunityNurseryAvailabilities: boolean;
  updateCommunityNurseryTemplateAvailabilities: boolean;
  updateCommunityNurseryZipCodes: boolean;
  testMutation?: boolean;
  createOrUpdateChildAdaptationForm?: IChildAdaptationForm;
  updateEcesu: IReceipt;
}

export interface ILoginInput {
  email: string;
  password: string;
}

export interface IAuthPayload {
  token: string;
  user: IUser;
}

export interface IResetUserPasswordInput {
  userId: string;
  password?: string;
  confirmPassword?: string;
  token?: string;
}

export interface IUpdateUserInput {
  id?: string;
  password?: string;
  nurseries?: string[];
}

export interface IActivateUserInput {
  userId: string;
  password?: string;
  token?: string;
}

export interface ICreateUserInput {
  firstName: string;
  lastName: string;
  email: string;
  role: IRole;
  nurseries?: string[];
}

export interface IUpdateNurseryInput {
  picture?: File;
  schedule?: IScheduleInput;
  visit?: IVisitInput;
  tags?: INurseryTag[];
  formularId?: string;
  lat?: number;
  lon?: number;
  description?: string;
  nurseryPlus?: string;
  information?: string;
  summerCampStartDate?: Date;
  summerCampEndDate?: Date;
  pedagogy?: string;
  tagInternationnal?: string;
  tagGraine?: string;
  tagBio?: string;
  cegidCode?: string;
  oneDayBeforeReservation?: boolean;
  onlyPhoneReservationEnable?: boolean;
  reservationPhone?: string;
  visitable?: boolean;
}

export interface IScheduleInput {
  morningStartRangeFirst: string;
  morningStartRangeSecond: string;
  morningEndRangeFirst: string;
  morningEndRangeSecond: string;
  afternoonStartRangeFirst: string;
  afternoonStartRangeSecond: string;
  afternoonEndRangeFirst: string;
  afternoonEndRangeSecond: string;
  openingHours: string;
  closingHours: string;
}

export interface IVisitInput {
  weekDay: number;
  start: string;
  end: string;
}

export interface IContactInput {
  subject: string;
  content: string;
  email: string;
  consent: boolean;
}

export interface IDspNurseryZipCodeInput {
  data: IUpdateDspNurseryZipCodeInput[];
}

export interface IUpdateDspNurseryZipCodeInput {
  id?: string;
  nursery: IDspNurseryInput;
  zipCode: string;
  createdAt?: Date;
  updatedAt?: Date;
}

export interface IDspNurseryInput {
  id: string;
}

export interface ICreateBanWhereInput {
  customer: string;
  nursery: string;
}

export interface ICreateBanInput {
  reason: IBanReason;
  description?: string;
}

export interface IBanWhereUniqueInput {
  id: string;
}

export interface IDocumentStatusUpdateDataInput {
  status?: IDocumentStatus;
  reason?: IDocumentRejectedReason;
  comment?: string;
  reservation?: IReservationWhereUniqueInput;
  registration?: IRegistrationWhereUniqueInput;
}

export interface IDocumentWhereInput {
  customer?: ICustomerWhereUniqueInput;
  child?: IUniqueChildInput;
}

export interface IDocumentCreateInput {
  file: File;
  type: IDocumentType;
}

export interface ICafDocumentWhereInput {
  customer?: ICustomerWhereUniqueInput;
}

export interface ICafDocumentCreateInput {
  file: File;
  title?: string;
}

export interface ICreateChildInput {
  id?: string;
  firstName: string;
  lastName: string;
  birthDate: Date;
  allergies?: IChildAllergiesInput;
  notes?: string;
  picture?: File;
  documents?: IDocumentCreateManyInput[];
  parent?: ICustomerConnect;
}

export interface IChildAllergiesInput {
  set?: IAllergy[];
}

export interface IDocumentCreateManyInput {
  file: File;
  type: IDocumentType;
}

export interface ICustomerConnect {
  connect: ICustomerWhereUniqueInput;
}

export interface IUpdateChildInput {
  firstName?: string;
  lastName?: string;
  birthDate?: Date;
  allergies?: IChildAllergiesInput;
  notes?: string;
  picture?: File;
  documents?: IDocumentUpdateInput;
}

export interface IDocumentUpdateInput {
  update?: IDocumentUpdateWithWhereUniqueNestedInput[];
}

export interface IDocumentUpdateWithWhereUniqueNestedInput {
  where: IWhereUniqueInput;
  data: IDocumentUpdateDataInput;
}

export interface IDocumentUpdateDataInput {
  url?: string;
  type?: IDocumentType;
  format?: IDocumentFormat;
  expirationDate?: Date;
  status?: IDocumentStatusUpdateOneRequiredInput;
}

export interface IDocumentStatusUpdateOneRequiredInput {
  update?: IDocumentStatusUpdateDataInput;
}

export interface ILoginCustomerInput {
  email: string;
  password: string;
}

export interface ICustomerWithToken {
  customer: ICustomer;
  token: string;
  isValidCompanyCode?: boolean;
}

export interface ICustomerUpdateInput {
  price?: number;
  isBanned?: boolean;
  isJobSeeker?: boolean;
  isResident?: boolean;
  email?: string;
  firstName?: string;
  lastName?: string;
  gender?: ICustomerGender;
  address?: string;
  zipCode?: string;
  city?: string;
  phone?: string;
  newsletter?: boolean;
  picture?: File;
  companyFirst?: string;
  companySecond?: string;
  companyCode?: string;
}

export interface ICustomerCreateInput {
  email: string;
  firstName: string;
  lastName: string;
  gender: ICustomerGender;
  address: string;
  zipCode: string;
  city: string;
  lang: ILangType;
  phone: string;
  password: string;
  confirmPassword: string;
  children: ICreateChildNestedInput[];
  picture?: File;
  newsletter: boolean;
  isJobSeeker: boolean;
  isResident: boolean;
  documents?: IDocumentCreateManyInput[];
  companyFirst?: string;
  companySecond?: string;
  companyCode?: string;
}

export interface ICreateChildNestedInput {
  id?: string;
  firstName: string;
  lastName: string;
  birthDate: Date;
  picture?: File;
  parent?: ICustomerConnect;
  documents?: IDocumentCreateManyInput[];
}

export interface ICustomerResetInput {
  email: string;
}

export interface ICustomerUpdatePasswordInput {
  customer: ICustomerWhereUniqueInput;
  token: string;
  password: string;
  confirmPassword: string;
}

export interface IUpdateCustomerPasswordInput {
  oldPassword: string;
  password: string;
  confirmPassword: string;
}

export interface IGroupedReservationCreationInput {
  reservationsCreation: IReservationCreationInput[];
}

export interface IReservationCreationInput {
  where: IReservationCreateWhereUniqueInput;
  data: IReservationCreateInput;
}

export interface IReservationCreateWhereUniqueInput {
  primaryId: string;
  secondaryId?: string;
}

export interface IReservationCreateInput {
  child: IUniqueChildInput;
  reservationFromHours?: number;
  reservationToHours?: number;
}

export interface IReservationUpdateInput {
  status?: IReservationStatus;
  statusReason?: IRejectReason;
  reasonComment?: string;
  price?: number;
}

export interface IGroupedReservationCancelInput {
  id_in: string[];
}

export interface INurseryConfigInput {
  price: number;
}

export interface INurseryConfig {
  id: string;
  price: number;
}

export interface IRegistrationUpdateInput {
  status: IRegistrationStatus;
}

export interface IRegistrationCreateInput {
  nursery: INurseryCreateOrConnectOneInput;
  child: IChildCreateOrConnectOneInput;
  reservation?: IReservationCreateOneOrConnectInput;
  welcoming?: IWelcomingCreateOrConnectOneInput;
  availability?: string[];
  secondaryAvailability?: string[];
  reservationFromHours?: number;
  reservationToHours?: number;
}

export interface INurseryCreateOrConnectOneInput {
  connect?: INurseryWhereUniqueInput;
}

export interface IChildCreateOrConnectOneInput {
  connect?: IChildWhereUniqueInput;
}

export interface IChildWhereUniqueInput {
  id?: string;
}

export interface IReservationCreateOneOrConnectInput {
  connect?: IReservationWhereUniqueInput;
}

export interface IWelcomingCreateOrConnectOneInput {
  create?: IWelcomingCreate;
}

export interface IWelcomingCreate {
  date: Date;
}

export interface IRegistrationCreation {
  registration?: IRegistration;
  reservations?: IReservation[];
}

export interface IMailWhereUniqueInput {
  id: string;
}

export interface IMailUpdateInput {
  name?: string;
  object?: string;
  content?: string;
  type?: IMailType;
}

export interface IFamilyCodeUpsertInput {
  customer: ICustomerWhereUniqueInput;
  nursery: INurseryWhereUniqueInput;
  codeFamily: string;
}

export interface ICreateBillingInput {
  name: string;
  date: Date;
  document: File;
  customer: ICustomerConnect;
}

export interface ICronInput {
  name: string;
  password: string;
}

export interface ICommunityNurseryAvailabilityInput {
  data: IUpdateCommunityNurseryAvailabilityInput[];
}

export interface IUpdateCommunityNurseryAvailabilityInput {
  id: string;
  nursery: ICommunityNurseryInput;
  analyticCode: string;
  type: IReservationType;
  code: string;
  capacity: number;
  remainingPlaces: number;
  date: Date;
  createdAt: Date;
  updatedAt: Date;
}

export interface ICommunityNurseryInput {
  id: string;
}

export interface ICommunityNurseryTemplateAvailabilityInput {
  data: IUpdateCommunityNurseryTemplateAvailabilityInput[];
}

export interface IUpdateCommunityNurseryTemplateAvailabilityInput {
  id: string;
  nursery: ICommunityNurseryInput;
  analyticCode: string;
  type: IReservationType;
  code: string;
  capacity: number;
  remainingPlaces: number;
  day: number;
  createdAt: Date;
  updatedAt: Date;
}

export interface ICommunityNurseryZipCodeInput {
  data: IUpdateCommunityNurseryZipCodeInput[];
}

export interface IUpdateCommunityNurseryZipCodeInput {
  id?: string;
  nursery: ICommunityNurseryInput;
  zipCode: string;
  createdAt?: Date;
  updatedAt?: Date;
}

export interface ICreateOrUpdateChildAdaptationFormInput {
  id?: string;
  parent: string;
  child: string;
  foodRestriction: boolean;
  foodRestrictionDescription?: string;
  breastfed: IBreastfedType;
  hygiene: IHygieneType;
  motorSkills: IMotorSkillsType[];
  morningSleepTime?: number;
  afternoonSleepTime?: number;
  allergy: boolean;
  allergyDescription?: string;
  comment?: string;
}

export interface IUpdateEcesuInput {
  reference: string;
  isCesuActive: boolean;
  eCesuAmount?: number;
  eCesuCode?: string;
}

export interface IcompanyCode {
  id: string;
  code: string;
}

export interface IEnable24hReservationHistory {
  id: string;
  nursery: INursery;
  user?: IUser;
  value: boolean;
  frenchDate?: string;
  utcDate: Date;
}

export interface IDocumentAccess {
  id: string;
  user?: IUser;
  customer?: ICustomer;
  document?: IDocument;
  cafDocument?: ICafDocument;
}

export interface IExcludedCustomerMail {
  id: string;
  customer: ICustomer;
}

export interface IPictureCreateInput {
  picture: File;
}

export interface IDocumentStatusCreateOneInput {
  create?: IDocumentStatusCreateInput;
  connect?: IWhereUniqueInput;
}

export interface IDocumentStatusCreateInput {
  id?: string;
  status: IDocumentStatus;
  reason?: IDocumentRejectedReason;
  comment?: string;
}

export interface INurseryReservationConnection {
  pageInfo: IPageInfo;
  aggregate: IAggregate;
  edges: IReservationEdge[];
}

export interface ISignupConnectionWhereInput {
  nursery?: string;
  status?: IRegistrationStatus;
  start?: Date;
  end?: Date;
}

export interface IReservationExtended {
  id: string;
  status: IReservationStatus;
  statusReason?: IRejectReason;
  requestType: IRequestType;
  child: IChild;
  nursery: INursery;
  section?: ISection;
  price?: number;
  type: IReservationType;
  date: Date;
  createdAt: Date;
  updatedAt: Date;
}

export interface IBanEdge {
  node: IBan;
}

export interface IBanConnection {
  pageInfo: IPageInfo;
  aggregate: IAggregate;
  edges?: IBanEdge[];
}

export interface ICustomersConnectionWhereInput {
  search?: string;
  orderBy?: ICustomersConnectionOrderEnum;
  filterBy?: ICustomersConnectionFilterEnum;
}

export interface IDocumentInput {
  type: IDocumentType;
  document: File;
}

export interface ICustomerActivateInput {
  customerId: string;
  token?: string;
}

export interface IPictureUpdateDataInput {
  url?: string;
}

export interface ISignUpInput {
  firstName: string;
  email: string;
  password: string;
}

export interface ICustomerAuthPayload {
  token: string;
  customer: ICustomer;
}

export interface ICheckEmailInput {
  email: string;
}

export interface IWelcomingWhereUniqueInput {
  id?: string;
}

export interface IAvailabilityWhereUniqueInput {
  id: string;
}

export interface IAvailabilityMultyEdgeTable {
  nursery: INursery;
  availabilities?: IAvailability[];
}

export interface IAvailabilityMultyEdge {
  node: IAvailabilityMultyEdgeTable;
}

export interface IAvailabilityMultiConnection {
  pageInfo: IPageInfo;
  aggregate: IAggregate;
  edges?: IAvailabilityMultyEdge[];
}

export interface IMoneticoDataInput {
  amount: number;
}

export interface IMoneticoInput {
  name: string;
  value: string;
}

export interface ICommunityNurseryTemplateInput {
  id: string;
}

export interface IzipCodeType {
  zipCode?: string;
  label?: string;
}

export type IRole = 'ADMIN' | 'DIRECTOR';

export type INurseryType = 'PAJE' | 'PSU' | 'AUTRE';

export type INurseryTag =
  | 'BIO_ALIMENTATION'
  | 'INTERNATIONNAL'
  | 'GENERATION_DURABLE'
  | 'ATELIER'
  | 'SUMMER_CAMP';

export type IReservationType = 'MORNING' | 'AFTERNOON' | 'ALL_DAY';

export type IReservationStatus =
  | 'DONE'
  | 'REFUSED'
  | 'PENDING'
  | 'PENDING_PAYMENT'
  | 'CANCELED'
  | 'EXPIRED';

export type IRejectReason = 'NO_STAFF' | 'NURSERY_CLOSED' | 'NO_SPACE' | 'BANNED_USER';

export type IDocumentType =
  | 'INSURANCE_CERTIFICATE'
  | 'MEDICAL_CERTIFICATE'
  | 'CHILD_HEALTH_RECORD'
  | 'BIRTH_CERTIFICATE'
  | 'PARENT_ID_RECTO'
  | 'PARENT_ID_VERSO'
  | 'CAF_IMMATRICULATION'
  | 'PROOF_OF_ADDRESS'
  | 'JOB_SEEKER_CERTIFICATE'
  | 'PAI_CERTIFICATE';

export type IDocumentStatus = 'OK' | 'DECLINED' | 'PENDING' | 'EXPIRED';

export type IDocumentFormat = 'PDF' | 'IMAGE';

export type IDocumentRejectedReason =
  | 'UNREADABLE'
  | 'DOCUMENT_ERROR'
  | 'INCOMPLETE'
  | 'OUTDATED'
  | 'MISSING';

export type ICustomerGender = 'MALE' | 'FEMALE';

export type ILangType = 'FR' | 'EN';

export type IBanReason = 'UNPAID' | 'CHILDREN_BEHAVIOR' | 'PARENT_BEHAVIOR' | 'RULES_FAILURE';

export type IAllergy = 'GLUTEN' | 'MILK_AND_EGGS' | 'FISH' | 'SULFUR_DIOXIDE' | 'EGGS';

export type IRegistrationStatus = 'PENDING' | 'REFUSED' | 'DONE';

export type IWelcomingStatus = 'PENDING' | 'DONE';

export type IReceiptStatus = 'PENDING' | 'SUCCESS' | 'REJECTED' | 'REFUNDED';

export type INurseryConnectionFilterEnum =
  | 'REGISTRATION_PENDING'
  | 'RESERVATION_PENDING'
  | 'REQUEST_HANDLED'
  | 'REVENUES'
  | 'NAME';

export type ICustomersConnectionOrderEnum = 'RESERVATION_PENDING' | 'RESERVATION_DONE';

export type ICustomersConnectionFilterEnum =
  | 'DOC_VALIDATED'
  | 'DOC_TO_VALIDATE'
  | 'DOC_NOT_COMPLETE'
  | 'DOC_MISSING';

export type IMailType = 'REGISTRATION' | 'RESERVATION';

export type IBreastfedType = 'EXCLUSIVE' | 'PARTIAL' | 'NO';

export type IHygieneType = 'YES' | 'IN_TRAINING_POTTY' | 'IN_TRAINING_WC' | 'NO';

export type IMotorSkillsType =
  | 'BACK_STOMACH'
  | 'SITS'
  | 'WALKS'
  | 'CRAWLS'
  | 'UP_DOWN_STAIRS'
  | 'NONE';

export type IRequestType = 'REGISTRATION' | 'RESERVATION';

export type IUnavailabilityReasonEnum =
  | 'OK'
  | 'KO_NURSERY_CLOSED'
  | 'KO_SECTION_UNAVAILABLE'
  | 'KO_TOO_SOON'
  | 'KO_NOT_IN_ZIP_CODE_LIST'
  | 'KO_HAVE_ALREADY_RESERVATION';

export type ICronEnum =
  | 'CRON_DISPONIBILITIES'
  | 'CRON_CLOSING_DATES'
  | 'CRON_NEW_NURESERY'
  | 'CRON_UPDATE_NURESERY'
  | 'CRON_UPDATE_COMMUNITY_NURESERY'
  | 'CRON_UPDATE_EFFORT_RATE'
  | 'CRON_REMOVE_DOCUMENT_CAF_IMMATRICULATION'
  | 'CRON_SET_SUMMER_CAMP'
  | 'CRON_UPDATE_NURSERY_STATUS';

export interface ImeArgs {}

export interface IuserArgs {
  where: IUserWhereUniqueInput;
}

export interface IusersConnectionArgs {
  where?: IUserConnectionWhereInput;
  page?: number;
  limit?: number;
}

export interface IrequestResetUserPasswordArgs {
  userId?: string;
}

export interface IrequestResetUserPasswordWithEmailArgs {
  userEmail?: string;
}

export interface InurseriesConnectionArgs {
  where?: INurseryConnectionWhereInput;
  page?: number;
  limit?: number;
}

export interface InurseriesSearchArgs {
  where: INurserySearchWhereInput;
  page?: number;
  limit?: number;
}

export interface InurseryArgs {
  where: INurseryUniqueInput;
}

export interface InurseryPlanningArgs {
  id: string;
  start: Date;
  end: Date;
}

export interface InurseryKeyFiguresArgs {
  id: string;
  start: Date;
  end: Date;
}

export interface InurseriesArgs {
  where?: INurseryConnectionWhereInput;
}

export interface IdspNurseryZipCodesArgs {
  nurseryId: string;
}

export interface IbansConnectionArgs {
  where?: IBansConnectionInput;
  page?: number;
  limit?: number;
}

export interface IdocumentArgs {
  id?: string;
}

export interface IgetDocumentUrlArgs {
  where: IWhereUniqueInput;
}

export interface IchildArgs {
  where: IWhereUniqueInput;
}

export interface IwaitingChildArgs {
  where?: IWhereWaitingChild;
  page?: number;
  limit?: number;
}

export interface IcustomerMeArgs {}

export interface IcustomerArgs {
  customerId: string;
}

export interface IcustomersConnectionArgs {
  where?: ICustomersExportWhereInput;
  page?: number;
  limit?: number;
}

export interface IexportCustomersArgs {
  where?: ICustomersExportWhereInput;
}

export interface IreservationsConnectionArgs {
  where?: IReservationConnectionWhereInput;
  page?: number;
  limit?: number;
}

export interface IgetAllReservationsArgs {
  where: IgetAllReservationsWhereInput;
  page?: number;
  limit?: number;
}

export interface IreservationArgs {
  id: string;
}

export interface IdashboardArgs {
  start?: Date;
  end?: Date;
}

export interface IcommunityNurseryDashboardArgs {
  start?: Date;
  end?: Date;
  nursery?: string;
}

export interface InurseryConfigArgs {
  isJobSeeker?: boolean;
}

export interface IregistrationsConnectionArgs {
  where: IRegistrationWhereInput;
  page?: number;
  limit?: number;
}

export interface IregistrationArgs {
  where: IRegistrationWhereUniqueInput;
}

export interface ImailsConnectionArgs {
  where?: IMailWhereInput;
  page?: number;
  limit?: number;
}

export interface IavailabilityArgs {
  where?: IWhereUniqueInput;
}

export interface IavailabilityMultipleArgs {
  where?: IAvailabilityMultipleInput;
}

export interface InurseriesAvailableArgs {
  where: IAvailabilitiesConnectionInput;
  page?: number;
  limit?: number;
}

export interface InurseriesAvailableMultipleArgs {
  where: IAvailabilitiesMultipleConnectionInput;
  page?: number;
  limit?: number;
}

export interface IgetMoneticoDataArgs {
  where: IReservationWhereMultiInput;
}

export interface IrefundReservationArgs {
  where: IReservationWhereUniqueInput;
}

export interface IexportPaymentsArgs {
  where?: INurseryWhereUniqueInput;
  start: Date;
  end: Date;
}

export interface IkeyStatisticsArgs {}

export interface InewNurseriesArgs {
  where: INurseriesConnectionWhereInput;
  page: number;
  limit: number;
}

export interface IcapacityStatisticsArgs {
  where?: ICapacityWhereInput;
  page: number;
  limit: number;
}

export interface IexportKeyStatisticsArgs {}

export interface IexportNewNurseriesArgs {
  where: INurseriesConnectionWhereInput;
}

export interface IexportCapacityStatisticsArgs {
  where?: ICapacityWhereInput;
}

export interface IexportUsersLogsAndDataArgs {
  where?: ILogsWhereInput;
}

export interface IexportReservationsNurseriesArgs {
  where?: IExportReservationsWhereInput;
}

export interface IfamilyCodeArgs {
  where: IFamilyCodeWhereInput;
}

export interface IbillingsConnectionArgs {
  where: IBillingConnectionWhereInput;
  page?: number;
  limit?: number;
}

export interface IgetBillingUrlArgs {
  where: IWhereUniqueInput;
}

export interface IloginlogArgs {
  where: ILoginLogUniqueInput;
}

export interface ItestArgs {
  data: boolean;
}

export interface IeffortRatesArgs {
  where: IWhereUniqueInput;
}

export interface IcommunityNurseryTemplateAvailabilitiesArgs {
  nurseryId: string;
}

export interface IcommunityNurseryZipCodesArgs {
  nurseryId: string;
}

export interface IgetPublicHolidaysArgs {}

export interface IgetZipCodesArgs {}

export interface IchildAdaptationFormArgs {
  where: IChildAdaptationFormInput;
}

export interface IuploadPictureArgs {
  picture: File;
}

export interface IdeletePictureArgs {
  id: string;
}

export interface IloginArgs {
  data?: ILoginInput;
}

export interface IresetUserPasswordArgs {
  data?: IResetUserPasswordInput;
}

export interface IupdateUserArgs {
  user?: IUpdateUserInput;
}

export interface IactivateUserArgs {
  data: IActivateUserInput;
}

export interface IdeleteUserArgs {
  userId: string;
}

export interface IcreateUserArgs {
  user: ICreateUserInput;
}

export interface IupdateNurseryArgs {
  id: string;
  data: IUpdateNurseryInput;
}

export interface IcontactUsArgs {
  data: IContactInput;
}

export interface IupdateDspNurseryZipCodesArgs {
  data: IDspNurseryZipCodeInput;
}

export interface IcreateBanArgs {
  where: ICreateBanWhereInput;
  data: ICreateBanInput;
}

export interface IdeleteBanArgs {
  where: IBanWhereUniqueInput;
}

export interface IcreateDocumentArgs {}

export interface IupdateDocumentArgs {
  where: IWhereUniqueInput;
  data: IDocumentStatusUpdateDataInput;
}

export interface IupdateDocumentCustomerArgs {
  where: IWhereUniqueInput;
  data: File;
}

export interface IuploadDocumentArgs {
  where: IDocumentWhereInput;
  data: IDocumentCreateInput;
}

export interface IuploadCafDocumentArgs {
  where: ICafDocumentWhereInput;
  data: ICafDocumentCreateInput;
}

export interface IdeleteCafDocumentArgs {
  cafDocumentId: string;
}

export interface IcreateChildArgs {
  data: ICreateChildInput;
}

export interface IupdateChildArgs {
  where: IWhereUniqueInput;
  data: IUpdateChildInput;
}

export interface IloginCustomerArgs {
  data?: ILoginCustomerInput;
}

export interface IupdateCustomerArgs {
  where?: ICustomerWhereUniqueInput;
  data?: ICustomerUpdateInput;
}

export interface IbanCustomerArgs {
  where: ICustomerWhereUniqueInput;
  data: ICreateBanInput;
}

export interface IregisterArgs {
  data: ICustomerCreateInput;
}

export interface IresetPasswordArgs {
  data: ICustomerResetInput;
}

export interface IupdatePasswordArgs {
  data: ICustomerUpdatePasswordInput;
}

export interface IdisableAccountArgs {}

export interface IdeleteAccountArgs {}

export interface IupdateCustomerPasswordArgs {
  data: IUpdateCustomerPasswordInput;
}

export interface IupdateCustomerLangArgs {
  data: ILangType;
}

export interface IcheckCompanyCodeArgs {
  data?: string;
}

export interface IcreateReservationArgs {
  data: IGroupedReservationCreationInput;
}

export interface IupdateReservationArgs {
  where: IReservationWhereUniqueInput;
  data: IReservationUpdateInput;
  rejectReasonConstant?: string;
}

export interface IcancelReservationArgs {
  where: IGroupedReservationCancelInput;
}

export interface IupdateNurseryConfigArgs {
  data: INurseryConfigInput;
}

export interface IupdateRegistrationArgs {
  where: IRegistrationWhereUniqueInput;
  data: IRegistrationUpdateInput;
}

export interface IcreateRegistrationArgs {
  data: IRegistrationCreateInput;
}

export interface IupdateMailArgs {
  where: IMailWhereUniqueInput;
  data?: IMailUpdateInput;
}

export interface IupsertFamilyCodeArgs {
  data: IFamilyCodeUpsertInput;
}

export interface IcreateBillingArgs {
  data: ICreateBillingInput;
}

export interface IdeleteBillingArgs {
  where: IWhereUniqueInput;
}

export interface IexecuteCronArgs {
  data: ICronInput;
}

export interface IupdateCommunityNurseryAvailabilitiesArgs {
  data: ICommunityNurseryAvailabilityInput;
}

export interface IupdateCommunityNurseryTemplateAvailabilitiesArgs {
  data: ICommunityNurseryTemplateAvailabilityInput;
}

export interface IupdateCommunityNurseryZipCodesArgs {
  data: ICommunityNurseryZipCodeInput;
}

export interface ItestMutationArgs {}

export interface IcreateOrUpdateChildAdaptationFormArgs {
  form: ICreateOrUpdateChildAdaptationFormInput;
}

export interface IupdateEcesuArgs {
  data: IUpdateEcesuInput;
}

import ApolloClient, {
  QueryOptions,
  OperationVariables,
  MutationOptions,
  ObservableQuery
} from 'apollo-client';
import { execute } from 'apollo-link';
import { OperationDefinitionNode, DocumentNode } from 'graphql';
import graphQlTag from 'graphql-tag';

export type AbortableQueryWithArgs<T, A> = {
  $args(args: A): AbortableQuery<T>;
  $abort(): void;
};

export type AbortableQueryWithOptionalArgs<T, A> = {
  $fetch(): Promise<T>;
  $args(args: A): AbortableQuery<T>;
  $abort(): void;
};

export type AbortableQuery<T> = {
  $fetch(): Promise<T>;
  $abort(): void;
};
export interface FragmentableQueryWithArgs<T, A> {
  $fragment(fragment: string | DocumentNode): AbortableQueryWithArgs<T, A>;
}
export interface FragmentableQueryWithOptionalArgs<T, A> {
  $fragment(fragment: string | DocumentNode): AbortableQueryWithOptionalArgs<T, A>;
}
export interface FragmentableQuery<T> {
  $fragment(fragment: string | DocumentNode): AbortableQuery<T>;
}

export type AbortableMutationWithArgs<T, A> = {
  $args(args: A): AbortableMutation<T>;
  $abort(): void;
};

export type AbortableMutationWithOptionalArgs<T, A> = {
  $post(): Promise<T>;
  $args(args: A): AbortableMutation<T>;
  $abort(): void;
};

export type AbortableMutation<T> = {
  $post(): Promise<T>;
  $abort(): void;
};

export interface FragmentableMutationWithArgs<T, A> {
  $fragment(fragment: string | DocumentNode): AbortableMutationWithArgs<T, A>;
}
export interface FragmentableMutationWithOptionalArgs<T, A> {
  $fragment(fragment: string | DocumentNode): AbortableMutationWithOptionalArgs<T, A>;
}
export interface FragmentableMutation<T> {
  $fragment(fragment: string | DocumentNode): AbortableMutation<T>;
}

const guessFragmentType = (fragment: string | DocumentNode) => {
  let isString,
    isFragment = false;
  let fragmentName = '';
  if (typeof fragment === 'string') {
    isString = true;
  } else if (typeof fragment === 'object' && fragment.definitions.length) {
    isFragment = true;
    const definition = fragment.definitions[0];
    if (definition.kind === 'FragmentDefinition') {
      fragmentName = definition.name.value;
    } else {
      console.error(
        `The argument passed is not a fragment definition, got ${definition.kind} instead`
      );
      return;
    }
  }
  return { isString, isFragment, fragmentName };
};

export const apiProvider = (apolloClient: ApolloClient<any>) => {
  const abortableQuery = <T, A = null>(
    query: DocumentNode,
    args: boolean
  ): A extends null ? AbortableQuery<T> : AbortableQueryWithArgs<T, A> => {
    let observableQuery: ZenObservable.Subscription;
    const parsedQuery = query.definitions[0] as OperationDefinitionNode;
    const queryName = parsedQuery.name.value;
    let variables: { [x: string]: any } = {};

    function $abort() {
      if (observableQuery && !observableQuery.closed) {
        observableQuery.unsubscribe();
      }
    }
    async function $fetch() {
      return new Promise<T>((resolve, reject) => {
        observableQuery = execute(apolloClient.link, {
          query,
          variables
        }).subscribe({
          next: ({ data, errors }) => {
            if (errors) {
              reject(errors);
            } else {
              resolve(data[queryName]);
            }
          },
          error: error => reject(error)
        });
      });
    }
    function $args(args) {
      variables = args;
      return {
        $abort,
        $fetch
      };
    }
    if (args) {
      return {
        $abort,
        $args
      } as any;
    } else {
      return {
        $abort,
        $fetch
      } as any;
    }
  };
  const abortableMutation = <T, A = null>(
    mutation: DocumentNode,
    args: boolean
  ): AbortableMutationWithArgs<T, A> => {
    let observableQuery: ZenObservable.Subscription;
    const parsedQuery = mutation.definitions[0] as OperationDefinitionNode;
    const mutationName = parsedQuery.name.value;
    let variables: { [x: string]: any } = {};

    function $abort() {
      if (observableQuery && !observableQuery.closed) {
        observableQuery.unsubscribe();
      }
    }
    async function $post() {
      return new Promise<T>((resolve, reject) => {
        observableQuery = execute(apolloClient.link, {
          query: mutation,
          variables
        }).subscribe({
          next: ({ data, errors }) => {
            if (errors) {
              reject(errors);
            } else {
              resolve(data[mutationName]);
            }
          },
          error: error => reject(error)
        });
      });
    }
    function $args(args) {
      variables = args;
      return {
        $abort,
        $post
      };
    }
    if (args) {
      return {
        $abort,
        $args
      } as any;
    } else {
      return {
        $abort,
        $post
      } as any;
    }
  };

  return {
    me(): FragmentableQuery<IUser> {
      return {
        $fragment: (fragment: string | DocumentNode) => {
          const { isString, isFragment, fragmentName } = guessFragmentType(fragment);
          const query = graphQlTag`
      query me  {
        me {
          ${isString ? fragment : '...' + fragmentName}
        }
      } ${isFragment ? fragment : ''}
      `;

          return abortableQuery(query, false) as any;
        }
      };
    },
    user(): FragmentableQueryWithArgs<IUser, IuserArgs> {
      return {
        $fragment: (fragment: string | DocumentNode) => {
          const { isString, isFragment, fragmentName } = guessFragmentType(fragment);
          const query = graphQlTag`
      query user ($where: UserWhereUniqueInput!) {
        user(where: $where) {
          ${isString ? fragment : '...' + fragmentName}
        }
      } ${isFragment ? fragment : ''}
      `;

          return abortableQuery(query, true) as any;
        }
      };
    },
    usersConnection(): FragmentableQueryWithOptionalArgs<IUserConnection, IusersConnectionArgs> {
      return {
        $fragment: (fragment: string | DocumentNode) => {
          const { isString, isFragment, fragmentName } = guessFragmentType(fragment);
          const query = graphQlTag`
      query usersConnection ($where: UserConnectionWhereInput,$page: Int,$limit: Int) {
        usersConnection(where: $where,page: $page,limit: $limit) {
          
    pageInfo {
        
    hasNextPage
    hasPreviousPage
    startCursor
    endCursor
    totalPage }
    aggregate {
        
    count }
    edges {
        
    node{${isString ? fragment : '...' + fragmentName}} }
        }
      } ${isFragment ? fragment : ''}
      `;

          return abortableQuery(query, true) as any;
        }
      };
    },
    requestResetUserPassword(): AbortableQueryWithOptionalArgs<
      boolean,
      IrequestResetUserPasswordArgs
    > {
      const query = graphQlTag`
      query requestResetUserPassword ($userId: ID) {
        requestResetUserPassword(userId: $userId)
      }`;
      return abortableQuery(query, true) as any;
    },
    requestResetUserPasswordWithEmail(): AbortableQueryWithOptionalArgs<
      boolean,
      IrequestResetUserPasswordWithEmailArgs
    > {
      const query = graphQlTag`
      query requestResetUserPasswordWithEmail ($userEmail: String) {
        requestResetUserPasswordWithEmail(userEmail: $userEmail)
      }`;
      return abortableQuery(query, true) as any;
    },
    nurseriesConnection(): FragmentableQueryWithOptionalArgs<
      INurseryConnectionWithRevenues,
      InurseriesConnectionArgs
    > {
      return {
        $fragment: (fragment: string | DocumentNode) => {
          const { isString, isFragment, fragmentName } = guessFragmentType(fragment);
          const query = graphQlTag`
      query nurseriesConnection ($where: NurseryConnectionWhereInput,$page: Int,$limit: Int) {
        nurseriesConnection(where: $where,page: $page,limit: $limit) {
          
    pageInfo {
        
    hasNextPage
    hasPreviousPage
    startCursor
    endCursor
    totalPage }
    aggregate {
        
    count }
    edges {
        
    node{${isString ? fragment : '...' + fragmentName}} }
        }
      } ${isFragment ? fragment : ''}
      `;

          return abortableQuery(query, true) as any;
        }
      };
    },
    nurseriesSearch(): FragmentableQueryWithArgs<INurseryConnection, InurseriesSearchArgs> {
      return {
        $fragment: (fragment: string | DocumentNode) => {
          const { isString, isFragment, fragmentName } = guessFragmentType(fragment);
          const query = graphQlTag`
      query nurseriesSearch ($where: NurserySearchWhereInput!,$page: Int,$limit: Int) {
        nurseriesSearch(where: $where,page: $page,limit: $limit) {
          
    pageInfo {
        
    hasNextPage
    hasPreviousPage
    startCursor
    endCursor
    totalPage }
    aggregate {
        
    count }
    edges {
        
    node{${isString ? fragment : '...' + fragmentName}} }
        }
      } ${isFragment ? fragment : ''}
      `;

          return abortableQuery(query, true) as any;
        }
      };
    },
    nursery(): FragmentableQueryWithArgs<INursery, InurseryArgs> {
      return {
        $fragment: (fragment: string | DocumentNode) => {
          const { isString, isFragment, fragmentName } = guessFragmentType(fragment);
          const query = graphQlTag`
      query nursery ($where: NurseryUniqueInput!) {
        nursery(where: $where) {
          ${isString ? fragment : '...' + fragmentName}
        }
      } ${isFragment ? fragment : ''}
      `;

          return abortableQuery(query, true) as any;
        }
      };
    },
    nurseryPlanning(): FragmentableQueryWithArgs<INurseryPlanning, InurseryPlanningArgs> {
      return {
        $fragment: (fragment: string | DocumentNode) => {
          const { isString, isFragment, fragmentName } = guessFragmentType(fragment);
          const query = graphQlTag`
      query nurseryPlanning ($id: ID!,$start: DateTime!,$end: DateTime!) {
        nurseryPlanning(id: $id,start: $start,end: $end) {
          ${isString ? fragment : '...' + fragmentName}
        }
      } ${isFragment ? fragment : ''}
      `;

          return abortableQuery(query, true) as any;
        }
      };
    },
    nurseryKeyFigures(): FragmentableQueryWithArgs<INurseryKeyFigures, InurseryKeyFiguresArgs> {
      return {
        $fragment: (fragment: string | DocumentNode) => {
          const { isString, isFragment, fragmentName } = guessFragmentType(fragment);
          const query = graphQlTag`
      query nurseryKeyFigures ($id: ID!,$start: DateTime!,$end: DateTime!) {
        nurseryKeyFigures(id: $id,start: $start,end: $end) {
          ${isString ? fragment : '...' + fragmentName}
        }
      } ${isFragment ? fragment : ''}
      `;

          return abortableQuery(query, true) as any;
        }
      };
    },
    nurseries(): FragmentableQueryWithOptionalArgs<INursery[], InurseriesArgs> {
      return {
        $fragment: (fragment: string | DocumentNode) => {
          const { isString, isFragment, fragmentName } = guessFragmentType(fragment);
          const query = graphQlTag`
      query nurseries ($where: NurseryConnectionWhereInput) {
        nurseries(where: $where) {
          ${isString ? fragment : '...' + fragmentName}
        }
      } ${isFragment ? fragment : ''}
      `;

          return abortableQuery(query, true) as any;
        }
      };
    },
    dspNurseryZipCodes(): FragmentableQueryWithArgs<IDspNurseryZipCode[], IdspNurseryZipCodesArgs> {
      return {
        $fragment: (fragment: string | DocumentNode) => {
          const { isString, isFragment, fragmentName } = guessFragmentType(fragment);
          const query = graphQlTag`
      query dspNurseryZipCodes ($nurseryId: ID!) {
        dspNurseryZipCodes(nurseryId: $nurseryId) {
          ${isString ? fragment : '...' + fragmentName}
        }
      } ${isFragment ? fragment : ''}
      `;

          return abortableQuery(query, true) as any;
        }
      };
    },
    bansConnection(): FragmentableQueryWithOptionalArgs<ICustomersConnection, IbansConnectionArgs> {
      return {
        $fragment: (fragment: string | DocumentNode) => {
          const { isString, isFragment, fragmentName } = guessFragmentType(fragment);
          const query = graphQlTag`
      query bansConnection ($where: BansConnectionInput,$page: Int,$limit: Int) {
        bansConnection(where: $where,page: $page,limit: $limit) {
          
    pageInfo {
        
    hasNextPage
    hasPreviousPage
    startCursor
    endCursor
    totalPage }
    aggregate {
        
    count }
    edges {
        
    node{${isString ? fragment : '...' + fragmentName}}
    cursor }
        }
      } ${isFragment ? fragment : ''}
      `;

          return abortableQuery(query, true) as any;
        }
      };
    },
    document(): FragmentableQueryWithOptionalArgs<IDocument, IdocumentArgs> {
      return {
        $fragment: (fragment: string | DocumentNode) => {
          const { isString, isFragment, fragmentName } = guessFragmentType(fragment);
          const query = graphQlTag`
      query document ($id: ID) {
        document(id: $id) {
          ${isString ? fragment : '...' + fragmentName}
        }
      } ${isFragment ? fragment : ''}
      `;

          return abortableQuery(query, true) as any;
        }
      };
    },
    getDocumentUrl(): AbortableQueryWithArgs<string, IgetDocumentUrlArgs> {
      const query = graphQlTag`
      query getDocumentUrl ($where: WhereUniqueInput!) {
        getDocumentUrl(where: $where)
      }`;
      return abortableQuery(query, true) as any;
    },
    child(): FragmentableQueryWithArgs<IChild, IchildArgs> {
      return {
        $fragment: (fragment: string | DocumentNode) => {
          const { isString, isFragment, fragmentName } = guessFragmentType(fragment);
          const query = graphQlTag`
      query child ($where: WhereUniqueInput!) {
        child(where: $where) {
          ${isString ? fragment : '...' + fragmentName}
        }
      } ${isFragment ? fragment : ''}
      `;

          return abortableQuery(query, true) as any;
        }
      };
    },
    waitingChild(): FragmentableQueryWithOptionalArgs<IChildConnection, IwaitingChildArgs> {
      return {
        $fragment: (fragment: string | DocumentNode) => {
          const { isString, isFragment, fragmentName } = guessFragmentType(fragment);
          const query = graphQlTag`
      query waitingChild ($where: WhereWaitingChild,$page: Int,$limit: Int) {
        waitingChild(where: $where,page: $page,limit: $limit) {
          
    pageInfo {
        
    hasNextPage
    hasPreviousPage
    startCursor
    endCursor
    totalPage }
    aggregate {
        
    count }
    edges {
        
    node{${isString ? fragment : '...' + fragmentName}} }
        }
      } ${isFragment ? fragment : ''}
      `;

          return abortableQuery(query, true) as any;
        }
      };
    },
    customerMe(): FragmentableQuery<ICustomer> {
      return {
        $fragment: (fragment: string | DocumentNode) => {
          const { isString, isFragment, fragmentName } = guessFragmentType(fragment);
          const query = graphQlTag`
      query customerMe  {
        customerMe {
          ${isString ? fragment : '...' + fragmentName}
        }
      } ${isFragment ? fragment : ''}
      `;

          return abortableQuery(query, false) as any;
        }
      };
    },
    customer(): FragmentableQueryWithArgs<ICustomerWithRegistration, IcustomerArgs> {
      return {
        $fragment: (fragment: string | DocumentNode) => {
          const { isString, isFragment, fragmentName } = guessFragmentType(fragment);
          const query = graphQlTag`
      query customer ($customerId: ID!) {
        customer(customerId: $customerId) {
          ${isString ? fragment : '...' + fragmentName}
        }
      } ${isFragment ? fragment : ''}
      `;

          return abortableQuery(query, true) as any;
        }
      };
    },
    customersConnection(): FragmentableQueryWithOptionalArgs<
      IRegisteredConnection,
      IcustomersConnectionArgs
    > {
      return {
        $fragment: (fragment: string | DocumentNode) => {
          const { isString, isFragment, fragmentName } = guessFragmentType(fragment);
          const query = graphQlTag`
      query customersConnection ($where: CustomersExportWhereInput,$page: Int,$limit: Int) {
        customersConnection(where: $where,page: $page,limit: $limit) {
          
    pageInfo {
        
    hasNextPage
    hasPreviousPage
    startCursor
    endCursor
    totalPage }
    aggregate {
        
    count }
    edges {
        
    node{${isString ? fragment : '...' + fragmentName}}
    cursor }
        }
      } ${isFragment ? fragment : ''}
      `;

          return abortableQuery(query, true) as any;
        }
      };
    },
    exportCustomers(): AbortableQueryWithOptionalArgs<string, IexportCustomersArgs> {
      const query = graphQlTag`
      query exportCustomers ($where: CustomersExportWhereInput) {
        exportCustomers(where: $where)
      }`;
      return abortableQuery(query, true) as any;
    },
    reservationsConnection(): FragmentableQueryWithOptionalArgs<
      IReservationConnection,
      IreservationsConnectionArgs
    > {
      return {
        $fragment: (fragment: string | DocumentNode) => {
          const { isString, isFragment, fragmentName } = guessFragmentType(fragment);
          const query = graphQlTag`
      query reservationsConnection ($where: ReservationConnectionWhereInput,$page: Int,$limit: Int) {
        reservationsConnection(where: $where,page: $page,limit: $limit) {
          
    pageInfo {
        
    hasNextPage
    hasPreviousPage
    startCursor
    endCursor
    totalPage }
    aggregate {
        
    count }
    edges {
        
    node{${isString ? fragment : '...' + fragmentName}} }
        }
      } ${isFragment ? fragment : ''}
      `;

          return abortableQuery(query, true) as any;
        }
      };
    },
    getAllReservations(): FragmentableQueryWithArgs<
      IReservationConnection,
      IgetAllReservationsArgs
    > {
      return {
        $fragment: (fragment: string | DocumentNode) => {
          const { isString, isFragment, fragmentName } = guessFragmentType(fragment);
          const query = graphQlTag`
      query getAllReservations ($where: getAllReservationsWhereInput!,$page: Int,$limit: Int) {
        getAllReservations(where: $where,page: $page,limit: $limit) {
          
    pageInfo {
        
    hasNextPage
    hasPreviousPage
    startCursor
    endCursor
    totalPage }
    aggregate {
        
    count }
    edges {
        
    node{${isString ? fragment : '...' + fragmentName}} }
        }
      } ${isFragment ? fragment : ''}
      `;

          return abortableQuery(query, true) as any;
        }
      };
    },
    reservation(): FragmentableQueryWithArgs<IReservation, IreservationArgs> {
      return {
        $fragment: (fragment: string | DocumentNode) => {
          const { isString, isFragment, fragmentName } = guessFragmentType(fragment);
          const query = graphQlTag`
      query reservation ($id: ID!) {
        reservation(id: $id) {
          ${isString ? fragment : '...' + fragmentName}
        }
      } ${isFragment ? fragment : ''}
      `;

          return abortableQuery(query, true) as any;
        }
      };
    },
    dashboard(): FragmentableQueryWithOptionalArgs<IDashboard, IdashboardArgs> {
      return {
        $fragment: (fragment: string | DocumentNode) => {
          const { isString, isFragment, fragmentName } = guessFragmentType(fragment);
          const query = graphQlTag`
      query dashboard ($start: DateTime,$end: DateTime) {
        dashboard(start: $start,end: $end) {
          ${isString ? fragment : '...' + fragmentName}
        }
      } ${isFragment ? fragment : ''}
      `;

          return abortableQuery(query, true) as any;
        }
      };
    },
    communityNurseryDashboard(): FragmentableQueryWithOptionalArgs<
      IDashboardCommunity,
      IcommunityNurseryDashboardArgs
    > {
      return {
        $fragment: (fragment: string | DocumentNode) => {
          const { isString, isFragment, fragmentName } = guessFragmentType(fragment);
          const query = graphQlTag`
      query communityNurseryDashboard ($start: DateTime,$end: DateTime,$nursery: String) {
        communityNurseryDashboard(start: $start,end: $end,nursery: $nursery) {
          ${isString ? fragment : '...' + fragmentName}
        }
      } ${isFragment ? fragment : ''}
      `;

          return abortableQuery(query, true) as any;
        }
      };
    },
    nurseryConfig(): FragmentableQueryWithOptionalArgs<
      INurseryConfigWithDefaultPrice,
      InurseryConfigArgs
    > {
      return {
        $fragment: (fragment: string | DocumentNode) => {
          const { isString, isFragment, fragmentName } = guessFragmentType(fragment);
          const query = graphQlTag`
      query nurseryConfig ($isJobSeeker: Boolean) {
        nurseryConfig(isJobSeeker: $isJobSeeker) {
          ${isString ? fragment : '...' + fragmentName}
        }
      } ${isFragment ? fragment : ''}
      `;

          return abortableQuery(query, true) as any;
        }
      };
    },
    registrationsConnection(): FragmentableQueryWithArgs<
      IRegistrationsConnection,
      IregistrationsConnectionArgs
    > {
      return {
        $fragment: (fragment: string | DocumentNode) => {
          const { isString, isFragment, fragmentName } = guessFragmentType(fragment);
          const query = graphQlTag`
      query registrationsConnection ($where: RegistrationWhereInput!,$page: Int,$limit: Int) {
        registrationsConnection(where: $where,page: $page,limit: $limit) {
          
    pageInfo {
        
    hasNextPage
    hasPreviousPage
    startCursor
    endCursor
    totalPage }
    aggregate {
        
    count }
    edges {
        
    node{${isString ? fragment : '...' + fragmentName}} }
        }
      } ${isFragment ? fragment : ''}
      `;

          return abortableQuery(query, true) as any;
        }
      };
    },
    registration(): FragmentableQueryWithArgs<IRegistration, IregistrationArgs> {
      return {
        $fragment: (fragment: string | DocumentNode) => {
          const { isString, isFragment, fragmentName } = guessFragmentType(fragment);
          const query = graphQlTag`
      query registration ($where: RegistrationWhereUniqueInput!) {
        registration(where: $where) {
          ${isString ? fragment : '...' + fragmentName}
        }
      } ${isFragment ? fragment : ''}
      `;

          return abortableQuery(query, true) as any;
        }
      };
    },
    mailsConnection(): FragmentableQueryWithOptionalArgs<IMailConnection, ImailsConnectionArgs> {
      return {
        $fragment: (fragment: string | DocumentNode) => {
          const { isString, isFragment, fragmentName } = guessFragmentType(fragment);
          const query = graphQlTag`
      query mailsConnection ($where: MailWhereInput,$page: Int,$limit: Int) {
        mailsConnection(where: $where,page: $page,limit: $limit) {
          
    pageInfo {
        
    hasNextPage
    hasPreviousPage
    startCursor
    endCursor
    totalPage }
    aggregate {
        
    count }
    edges {
        
    node{${isString ? fragment : '...' + fragmentName}} }
        }
      } ${isFragment ? fragment : ''}
      `;

          return abortableQuery(query, true) as any;
        }
      };
    },
    availability(): FragmentableQueryWithOptionalArgs<IAvailability, IavailabilityArgs> {
      return {
        $fragment: (fragment: string | DocumentNode) => {
          const { isString, isFragment, fragmentName } = guessFragmentType(fragment);
          const query = graphQlTag`
      query availability ($where: WhereUniqueInput) {
        availability(where: $where) {
          ${isString ? fragment : '...' + fragmentName}
        }
      } ${isFragment ? fragment : ''}
      `;

          return abortableQuery(query, true) as any;
        }
      };
    },
    availabilityMultiple(): FragmentableQueryWithOptionalArgs<
      IAvailability[],
      IavailabilityMultipleArgs
    > {
      return {
        $fragment: (fragment: string | DocumentNode) => {
          const { isString, isFragment, fragmentName } = guessFragmentType(fragment);
          const query = graphQlTag`
      query availabilityMultiple ($where: AvailabilityMultipleInput) {
        availabilityMultiple(where: $where) {
          ${isString ? fragment : '...' + fragmentName}
        }
      } ${isFragment ? fragment : ''}
      `;

          return abortableQuery(query, true) as any;
        }
      };
    },
    nurseriesAvailable(): FragmentableQueryWithArgs<
      IAvailabilityConnection,
      InurseriesAvailableArgs
    > {
      return {
        $fragment: (fragment: string | DocumentNode) => {
          const { isString, isFragment, fragmentName } = guessFragmentType(fragment);
          const query = graphQlTag`
      query nurseriesAvailable ($where: AvailabilitiesConnectionInput!,$page: Int,$limit: Int) {
        nurseriesAvailable(where: $where,page: $page,limit: $limit) {
          
    pageInfo {
        
    hasNextPage
    hasPreviousPage
    startCursor
    endCursor
    totalPage }
    aggregate {
        
    count }
    edges {
        
    node{${isString ? fragment : '...' + fragmentName}} }
        }
      } ${isFragment ? fragment : ''}
      `;

          return abortableQuery(query, true) as any;
        }
      };
    },
    nurseriesAvailableMultiple(): FragmentableQueryWithArgs<
      IMultipleAvailabilityConnection,
      InurseriesAvailableMultipleArgs
    > {
      return {
        $fragment: (fragment: string | DocumentNode) => {
          const { isString, isFragment, fragmentName } = guessFragmentType(fragment);
          const query = graphQlTag`
      query nurseriesAvailableMultiple ($where: AvailabilitiesMultipleConnectionInput!,$page: Int,$limit: Int) {
        nurseriesAvailableMultiple(where: $where,page: $page,limit: $limit) {
          
    pageInfo {
        
    hasNextPage
    hasPreviousPage
    startCursor
    endCursor
    totalPage }
    aggregate {
        
    count }
    edges {
        
    node{${isString ? fragment : '...' + fragmentName}} }
    unavailabilitiesReason {
        
    date
    reason }
        }
      } ${isFragment ? fragment : ''}
      `;

          return abortableQuery(query, true) as any;
        }
      };
    },
    getMoneticoData(): FragmentableQueryWithArgs<IMonetico, IgetMoneticoDataArgs> {
      return {
        $fragment: (fragment: string | DocumentNode) => {
          const { isString, isFragment, fragmentName } = guessFragmentType(fragment);
          const query = graphQlTag`
      query getMoneticoData ($where: ReservationWhereMultiInput!) {
        getMoneticoData(where: $where) {
          ${isString ? fragment : '...' + fragmentName}
        }
      } ${isFragment ? fragment : ''}
      `;

          return abortableQuery(query, true) as any;
        }
      };
    },
    refundReservation(): AbortableQueryWithArgs<boolean, IrefundReservationArgs> {
      const query = graphQlTag`
      query refundReservation ($where: ReservationWhereUniqueInput!) {
        refundReservation(where: $where)
      }`;
      return abortableQuery(query, true) as any;
    },
    exportPayments(): AbortableQueryWithArgs<string, IexportPaymentsArgs> {
      const query = graphQlTag`
      query exportPayments ($where: NurseryWhereUniqueInput,$start: DateTime!,$end: DateTime!) {
        exportPayments(where: $where,start: $start,end: $end)
      }`;
      return abortableQuery(query, true) as any;
    },
    keyStatistics(): FragmentableQuery<IKeyStatistics> {
      return {
        $fragment: (fragment: string | DocumentNode) => {
          const { isString, isFragment, fragmentName } = guessFragmentType(fragment);
          const query = graphQlTag`
      query keyStatistics  {
        keyStatistics {
          ${isString ? fragment : '...' + fragmentName}
        }
      } ${isFragment ? fragment : ''}
      `;

          return abortableQuery(query, false) as any;
        }
      };
    },
    newNurseries(): FragmentableQueryWithArgs<INurseryConnection, InewNurseriesArgs> {
      return {
        $fragment: (fragment: string | DocumentNode) => {
          const { isString, isFragment, fragmentName } = guessFragmentType(fragment);
          const query = graphQlTag`
      query newNurseries ($where: NurseriesConnectionWhereInput!,$page: Int!,$limit: Int!) {
        newNurseries(where: $where,page: $page,limit: $limit) {
          
    pageInfo {
        
    hasNextPage
    hasPreviousPage
    startCursor
    endCursor
    totalPage }
    aggregate {
        
    count }
    edges {
        
    node{${isString ? fragment : '...' + fragmentName}} }
        }
      } ${isFragment ? fragment : ''}
      `;

          return abortableQuery(query, true) as any;
        }
      };
    },
    capacityStatistics(): FragmentableQueryWithArgs<
      ICapacityStatistics[],
      IcapacityStatisticsArgs
    > {
      return {
        $fragment: (fragment: string | DocumentNode) => {
          const { isString, isFragment, fragmentName } = guessFragmentType(fragment);
          const query = graphQlTag`
      query capacityStatistics ($where: CapacityWhereInput,$page: Int!,$limit: Int!) {
        capacityStatistics(where: $where,page: $page,limit: $limit) {
          ${isString ? fragment : '...' + fragmentName}
        }
      } ${isFragment ? fragment : ''}
      `;

          return abortableQuery(query, true) as any;
        }
      };
    },
    exportKeyStatistics(): AbortableQuery<string> {
      const query = graphQlTag`
      query exportKeyStatistics  {
        exportKeyStatistics
      }`;
      return abortableQuery(query, false) as any;
    },
    exportNewNurseries(): AbortableQueryWithArgs<string, IexportNewNurseriesArgs> {
      const query = graphQlTag`
      query exportNewNurseries ($where: NurseriesConnectionWhereInput!) {
        exportNewNurseries(where: $where)
      }`;
      return abortableQuery(query, true) as any;
    },
    exportCapacityStatistics(): AbortableQueryWithOptionalArgs<
      string,
      IexportCapacityStatisticsArgs
    > {
      const query = graphQlTag`
      query exportCapacityStatistics ($where: CapacityWhereInput) {
        exportCapacityStatistics(where: $where)
      }`;
      return abortableQuery(query, true) as any;
    },
    exportUsersLogsAndData(): AbortableQueryWithOptionalArgs<string, IexportUsersLogsAndDataArgs> {
      const query = graphQlTag`
      query exportUsersLogsAndData ($where: LogsWhereInput) {
        exportUsersLogsAndData(where: $where)
      }`;
      return abortableQuery(query, true) as any;
    },
    exportReservationsNurseries(): AbortableQueryWithOptionalArgs<
      string,
      IexportReservationsNurseriesArgs
    > {
      const query = graphQlTag`
      query exportReservationsNurseries ($where: ExportReservationsWhereInput) {
        exportReservationsNurseries(where: $where)
      }`;
      return abortableQuery(query, true) as any;
    },
    familyCode(): FragmentableQueryWithArgs<IFamilyCode, IfamilyCodeArgs> {
      return {
        $fragment: (fragment: string | DocumentNode) => {
          const { isString, isFragment, fragmentName } = guessFragmentType(fragment);
          const query = graphQlTag`
      query familyCode ($where: FamilyCodeWhereInput!) {
        familyCode(where: $where) {
          ${isString ? fragment : '...' + fragmentName}
        }
      } ${isFragment ? fragment : ''}
      `;

          return abortableQuery(query, true) as any;
        }
      };
    },
    billingsConnection(): FragmentableQueryWithArgs<IBillingsConnection, IbillingsConnectionArgs> {
      return {
        $fragment: (fragment: string | DocumentNode) => {
          const { isString, isFragment, fragmentName } = guessFragmentType(fragment);
          const query = graphQlTag`
      query billingsConnection ($where: BillingConnectionWhereInput!,$page: Int,$limit: Int) {
        billingsConnection(where: $where,page: $page,limit: $limit) {
          
    pageInfo {
        
    hasNextPage
    hasPreviousPage
    startCursor
    endCursor
    totalPage }
    aggregate {
        
    count }
    edges {
        
    node{${isString ? fragment : '...' + fragmentName}} }
        }
      } ${isFragment ? fragment : ''}
      `;

          return abortableQuery(query, true) as any;
        }
      };
    },
    getBillingUrl(): AbortableQueryWithArgs<string, IgetBillingUrlArgs> {
      const query = graphQlTag`
      query getBillingUrl ($where: WhereUniqueInput!) {
        getBillingUrl(where: $where)
      }`;
      return abortableQuery(query, true) as any;
    },
    loginlog(): FragmentableQueryWithArgs<ILoginlog, IloginlogArgs> {
      return {
        $fragment: (fragment: string | DocumentNode) => {
          const { isString, isFragment, fragmentName } = guessFragmentType(fragment);
          const query = graphQlTag`
      query loginlog ($where: LoginLogUniqueInput!) {
        loginlog(where: $where) {
          ${isString ? fragment : '...' + fragmentName}
        }
      } ${isFragment ? fragment : ''}
      `;

          return abortableQuery(query, true) as any;
        }
      };
    },
    test(): AbortableQueryWithArgs<boolean, ItestArgs> {
      const query = graphQlTag`
      query test ($data: Boolean!) {
        test(data: $data)
      }`;
      return abortableQuery(query, true) as any;
    },
    effortRates(): FragmentableQueryWithArgs<IEffortRate[], IeffortRatesArgs> {
      return {
        $fragment: (fragment: string | DocumentNode) => {
          const { isString, isFragment, fragmentName } = guessFragmentType(fragment);
          const query = graphQlTag`
      query effortRates ($where: WhereUniqueInput!) {
        effortRates(where: $where) {
          ${isString ? fragment : '...' + fragmentName}
        }
      } ${isFragment ? fragment : ''}
      `;

          return abortableQuery(query, true) as any;
        }
      };
    },
    communityNurseryTemplateAvailabilities(): FragmentableQueryWithArgs<
      ICommunityNurseryTemplateAvailability[],
      IcommunityNurseryTemplateAvailabilitiesArgs
    > {
      return {
        $fragment: (fragment: string | DocumentNode) => {
          const { isString, isFragment, fragmentName } = guessFragmentType(fragment);
          const query = graphQlTag`
      query communityNurseryTemplateAvailabilities ($nurseryId: ID!) {
        communityNurseryTemplateAvailabilities(nurseryId: $nurseryId) {
          ${isString ? fragment : '...' + fragmentName}
        }
      } ${isFragment ? fragment : ''}
      `;

          return abortableQuery(query, true) as any;
        }
      };
    },
    communityNurseryZipCodes(): FragmentableQueryWithArgs<
      ICommunityNurseryZipCode[],
      IcommunityNurseryZipCodesArgs
    > {
      return {
        $fragment: (fragment: string | DocumentNode) => {
          const { isString, isFragment, fragmentName } = guessFragmentType(fragment);
          const query = graphQlTag`
      query communityNurseryZipCodes ($nurseryId: ID!) {
        communityNurseryZipCodes(nurseryId: $nurseryId) {
          ${isString ? fragment : '...' + fragmentName}
        }
      } ${isFragment ? fragment : ''}
      `;

          return abortableQuery(query, true) as any;
        }
      };
    },
    getPublicHolidays(): AbortableQuery<string[]> {
      const query = graphQlTag`
      query getPublicHolidays  {
        getPublicHolidays
      }`;
      return abortableQuery(query, false) as any;
    },
    getZipCodes(): AbortableQuery<string> {
      const query = graphQlTag`
      query getZipCodes  {
        getZipCodes
      }`;
      return abortableQuery(query, false) as any;
    },
    childAdaptationForm(): FragmentableQueryWithArgs<
      IChildAdaptationForm[],
      IchildAdaptationFormArgs
    > {
      return {
        $fragment: (fragment: string | DocumentNode) => {
          const { isString, isFragment, fragmentName } = guessFragmentType(fragment);
          const query = graphQlTag`
      query childAdaptationForm ($where: ChildAdaptationFormInput!) {
        childAdaptationForm(where: $where) {
          ${isString ? fragment : '...' + fragmentName}
        }
      } ${isFragment ? fragment : ''}
      `;

          return abortableQuery(query, true) as any;
        }
      };
    },
    uploadPicture(): FragmentableMutationWithArgs<IPicture, IuploadPictureArgs> {
      return {
        $fragment: (fragment: string | DocumentNode) => {
          const { isString, isFragment, fragmentName } = guessFragmentType(fragment);
          const mutation = graphQlTag`
      mutation uploadPicture ($picture: Upload!) {
        uploadPicture(picture: $picture) {
          ${isString ? fragment : '...' + fragmentName}
        }
      } ${isFragment ? fragment : ''}
      `;

          return abortableMutation(mutation, true) as any;
        }
      };
    },
    deletePicture(): AbortableMutationWithArgs<boolean, IdeletePictureArgs> {
      const mutation = graphQlTag`
      mutation deletePicture ($id: ID!) {
        deletePicture(id: $id)
      }`;
      return abortableMutation(mutation, true) as any;
    },
    login(): FragmentableMutationWithOptionalArgs<IAuthPayload, IloginArgs> {
      return {
        $fragment: (fragment: string | DocumentNode) => {
          const { isString, isFragment, fragmentName } = guessFragmentType(fragment);
          const mutation = graphQlTag`
      mutation login ($data: LoginInput) {
        login(data: $data) {
          ${isString ? fragment : '...' + fragmentName}
        }
      } ${isFragment ? fragment : ''}
      `;

          return abortableMutation(mutation, true) as any;
        }
      };
    },
    resetUserPassword(): AbortableMutationWithOptionalArgs<boolean, IresetUserPasswordArgs> {
      const mutation = graphQlTag`
      mutation resetUserPassword ($data: ResetUserPasswordInput) {
        resetUserPassword(data: $data)
      }`;
      return abortableMutation(mutation, true) as any;
    },
    updateUser(): FragmentableMutationWithOptionalArgs<IUser, IupdateUserArgs> {
      return {
        $fragment: (fragment: string | DocumentNode) => {
          const { isString, isFragment, fragmentName } = guessFragmentType(fragment);
          const mutation = graphQlTag`
      mutation updateUser ($user: UpdateUserInput) {
        updateUser(user: $user) {
          ${isString ? fragment : '...' + fragmentName}
        }
      } ${isFragment ? fragment : ''}
      `;

          return abortableMutation(mutation, true) as any;
        }
      };
    },
    activateUser(): FragmentableMutationWithArgs<IAuthPayload, IactivateUserArgs> {
      return {
        $fragment: (fragment: string | DocumentNode) => {
          const { isString, isFragment, fragmentName } = guessFragmentType(fragment);
          const mutation = graphQlTag`
      mutation activateUser ($data: ActivateUserInput!) {
        activateUser(data: $data) {
          ${isString ? fragment : '...' + fragmentName}
        }
      } ${isFragment ? fragment : ''}
      `;

          return abortableMutation(mutation, true) as any;
        }
      };
    },
    deleteUser(): AbortableMutationWithArgs<boolean, IdeleteUserArgs> {
      const mutation = graphQlTag`
      mutation deleteUser ($userId: ID!) {
        deleteUser(userId: $userId)
      }`;
      return abortableMutation(mutation, true) as any;
    },
    createUser(): FragmentableMutationWithArgs<IUser, IcreateUserArgs> {
      return {
        $fragment: (fragment: string | DocumentNode) => {
          const { isString, isFragment, fragmentName } = guessFragmentType(fragment);
          const mutation = graphQlTag`
      mutation createUser ($user: CreateUserInput!) {
        createUser(user: $user) {
          ${isString ? fragment : '...' + fragmentName}
        }
      } ${isFragment ? fragment : ''}
      `;

          return abortableMutation(mutation, true) as any;
        }
      };
    },
    updateNursery(): FragmentableMutationWithArgs<INursery, IupdateNurseryArgs> {
      return {
        $fragment: (fragment: string | DocumentNode) => {
          const { isString, isFragment, fragmentName } = guessFragmentType(fragment);
          const mutation = graphQlTag`
      mutation updateNursery ($id: ID!,$data: UpdateNurseryInput!) {
        updateNursery(id: $id,data: $data) {
          ${isString ? fragment : '...' + fragmentName}
        }
      } ${isFragment ? fragment : ''}
      `;

          return abortableMutation(mutation, true) as any;
        }
      };
    },
    contactUs(): AbortableMutationWithArgs<boolean, IcontactUsArgs> {
      const mutation = graphQlTag`
      mutation contactUs ($data: ContactInput!) {
        contactUs(data: $data)
      }`;
      return abortableMutation(mutation, true) as any;
    },
    updateDspNurseryZipCodes(): AbortableMutationWithArgs<boolean, IupdateDspNurseryZipCodesArgs> {
      const mutation = graphQlTag`
      mutation updateDspNurseryZipCodes ($data: DspNurseryZipCodeInput!) {
        updateDspNurseryZipCodes(data: $data)
      }`;
      return abortableMutation(mutation, true) as any;
    },
    createBan(): FragmentableMutationWithArgs<IBan, IcreateBanArgs> {
      return {
        $fragment: (fragment: string | DocumentNode) => {
          const { isString, isFragment, fragmentName } = guessFragmentType(fragment);
          const mutation = graphQlTag`
      mutation createBan ($where: CreateBanWhereInput!,$data: CreateBanInput!) {
        createBan(where: $where,data: $data) {
          ${isString ? fragment : '...' + fragmentName}
        }
      } ${isFragment ? fragment : ''}
      `;

          return abortableMutation(mutation, true) as any;
        }
      };
    },
    deleteBan(): AbortableMutationWithArgs<boolean, IdeleteBanArgs> {
      const mutation = graphQlTag`
      mutation deleteBan ($where: BanWhereUniqueInput!) {
        deleteBan(where: $where)
      }`;
      return abortableMutation(mutation, true) as any;
    },
    createDocument(): FragmentableMutation<IDocument> {
      return {
        $fragment: (fragment: string | DocumentNode) => {
          const { isString, isFragment, fragmentName } = guessFragmentType(fragment);
          const mutation = graphQlTag`
      mutation createDocument  {
        createDocument {
          ${isString ? fragment : '...' + fragmentName}
        }
      } ${isFragment ? fragment : ''}
      `;

          return abortableMutation(mutation, false) as any;
        }
      };
    },
    updateDocument(): FragmentableMutationWithArgs<IDocument, IupdateDocumentArgs> {
      return {
        $fragment: (fragment: string | DocumentNode) => {
          const { isString, isFragment, fragmentName } = guessFragmentType(fragment);
          const mutation = graphQlTag`
      mutation updateDocument ($where: WhereUniqueInput!,$data: DocumentStatusUpdateDataInput!) {
        updateDocument(where: $where,data: $data) {
          ${isString ? fragment : '...' + fragmentName}
        }
      } ${isFragment ? fragment : ''}
      `;

          return abortableMutation(mutation, true) as any;
        }
      };
    },
    updateDocumentCustomer(): FragmentableMutationWithArgs<IDocument, IupdateDocumentCustomerArgs> {
      return {
        $fragment: (fragment: string | DocumentNode) => {
          const { isString, isFragment, fragmentName } = guessFragmentType(fragment);
          const mutation = graphQlTag`
      mutation updateDocumentCustomer ($where: WhereUniqueInput!,$data: Upload!) {
        updateDocumentCustomer(where: $where,data: $data) {
          ${isString ? fragment : '...' + fragmentName}
        }
      } ${isFragment ? fragment : ''}
      `;

          return abortableMutation(mutation, true) as any;
        }
      };
    },
    uploadDocument(): FragmentableMutationWithArgs<IDocument, IuploadDocumentArgs> {
      return {
        $fragment: (fragment: string | DocumentNode) => {
          const { isString, isFragment, fragmentName } = guessFragmentType(fragment);
          const mutation = graphQlTag`
      mutation uploadDocument ($where: DocumentWhereInput!,$data: DocumentCreateInput!) {
        uploadDocument(where: $where,data: $data) {
          ${isString ? fragment : '...' + fragmentName}
        }
      } ${isFragment ? fragment : ''}
      `;

          return abortableMutation(mutation, true) as any;
        }
      };
    },
    uploadCafDocument(): FragmentableMutationWithArgs<ICafDocument, IuploadCafDocumentArgs> {
      return {
        $fragment: (fragment: string | DocumentNode) => {
          const { isString, isFragment, fragmentName } = guessFragmentType(fragment);
          const mutation = graphQlTag`
      mutation uploadCafDocument ($where: CafDocumentWhereInput!,$data: CafDocumentCreateInput!) {
        uploadCafDocument(where: $where,data: $data) {
          ${isString ? fragment : '...' + fragmentName}
        }
      } ${isFragment ? fragment : ''}
      `;

          return abortableMutation(mutation, true) as any;
        }
      };
    },
    deleteCafDocument(): AbortableMutationWithArgs<boolean, IdeleteCafDocumentArgs> {
      const mutation = graphQlTag`
      mutation deleteCafDocument ($cafDocumentId: ID!) {
        deleteCafDocument(cafDocumentId: $cafDocumentId)
      }`;
      return abortableMutation(mutation, true) as any;
    },
    createChild(): FragmentableMutationWithArgs<IChild, IcreateChildArgs> {
      return {
        $fragment: (fragment: string | DocumentNode) => {
          const { isString, isFragment, fragmentName } = guessFragmentType(fragment);
          const mutation = graphQlTag`
      mutation createChild ($data: CreateChildInput!) {
        createChild(data: $data) {
          ${isString ? fragment : '...' + fragmentName}
        }
      } ${isFragment ? fragment : ''}
      `;

          return abortableMutation(mutation, true) as any;
        }
      };
    },
    updateChild(): FragmentableMutationWithArgs<IChild, IupdateChildArgs> {
      return {
        $fragment: (fragment: string | DocumentNode) => {
          const { isString, isFragment, fragmentName } = guessFragmentType(fragment);
          const mutation = graphQlTag`
      mutation updateChild ($where: WhereUniqueInput!,$data: UpdateChildInput!) {
        updateChild(where: $where,data: $data) {
          ${isString ? fragment : '...' + fragmentName}
        }
      } ${isFragment ? fragment : ''}
      `;

          return abortableMutation(mutation, true) as any;
        }
      };
    },
    loginCustomer(): FragmentableMutationWithOptionalArgs<ICustomerWithToken, IloginCustomerArgs> {
      return {
        $fragment: (fragment: string | DocumentNode) => {
          const { isString, isFragment, fragmentName } = guessFragmentType(fragment);
          const mutation = graphQlTag`
      mutation loginCustomer ($data: LoginCustomerInput) {
        loginCustomer(data: $data) {
          ${isString ? fragment : '...' + fragmentName}
        }
      } ${isFragment ? fragment : ''}
      `;

          return abortableMutation(mutation, true) as any;
        }
      };
    },
    updateCustomer(): FragmentableMutationWithOptionalArgs<ICustomer, IupdateCustomerArgs> {
      return {
        $fragment: (fragment: string | DocumentNode) => {
          const { isString, isFragment, fragmentName } = guessFragmentType(fragment);
          const mutation = graphQlTag`
      mutation updateCustomer ($where: CustomerWhereUniqueInput,$data: CustomerUpdateInput) {
        updateCustomer(where: $where,data: $data) {
          ${isString ? fragment : '...' + fragmentName}
        }
      } ${isFragment ? fragment : ''}
      `;

          return abortableMutation(mutation, true) as any;
        }
      };
    },
    banCustomer(): FragmentableMutationWithArgs<ICustomer, IbanCustomerArgs> {
      return {
        $fragment: (fragment: string | DocumentNode) => {
          const { isString, isFragment, fragmentName } = guessFragmentType(fragment);
          const mutation = graphQlTag`
      mutation banCustomer ($where: CustomerWhereUniqueInput!,$data: CreateBanInput!) {
        banCustomer(where: $where,data: $data) {
          ${isString ? fragment : '...' + fragmentName}
        }
      } ${isFragment ? fragment : ''}
      `;

          return abortableMutation(mutation, true) as any;
        }
      };
    },
    register(): FragmentableMutationWithArgs<ICustomerWithToken, IregisterArgs> {
      return {
        $fragment: (fragment: string | DocumentNode) => {
          const { isString, isFragment, fragmentName } = guessFragmentType(fragment);
          const mutation = graphQlTag`
      mutation register ($data: CustomerCreateInput!) {
        register(data: $data) {
          ${isString ? fragment : '...' + fragmentName}
        }
      } ${isFragment ? fragment : ''}
      `;

          return abortableMutation(mutation, true) as any;
        }
      };
    },
    resetPassword(): AbortableMutationWithArgs<boolean, IresetPasswordArgs> {
      const mutation = graphQlTag`
      mutation resetPassword ($data: CustomerResetInput!) {
        resetPassword(data: $data)
      }`;
      return abortableMutation(mutation, true) as any;
    },
    updatePassword(): AbortableMutationWithArgs<boolean, IupdatePasswordArgs> {
      const mutation = graphQlTag`
      mutation updatePassword ($data: CustomerUpdatePasswordInput!) {
        updatePassword(data: $data)
      }`;
      return abortableMutation(mutation, true) as any;
    },
    disableAccount(): AbortableMutation<boolean> {
      const mutation = graphQlTag`
      mutation disableAccount  {
        disableAccount
      }`;
      return abortableMutation(mutation, false) as any;
    },
    deleteAccount(): AbortableMutation<boolean> {
      const mutation = graphQlTag`
      mutation deleteAccount  {
        deleteAccount
      }`;
      return abortableMutation(mutation, false) as any;
    },
    updateCustomerPassword(): FragmentableMutationWithArgs<ICustomer, IupdateCustomerPasswordArgs> {
      return {
        $fragment: (fragment: string | DocumentNode) => {
          const { isString, isFragment, fragmentName } = guessFragmentType(fragment);
          const mutation = graphQlTag`
      mutation updateCustomerPassword ($data: UpdateCustomerPasswordInput!) {
        updateCustomerPassword(data: $data) {
          ${isString ? fragment : '...' + fragmentName}
        }
      } ${isFragment ? fragment : ''}
      `;

          return abortableMutation(mutation, true) as any;
        }
      };
    },
    updateCustomerLang(): AbortableMutationWithArgs<boolean, IupdateCustomerLangArgs> {
      const mutation = graphQlTag`
      mutation updateCustomerLang ($data: LangType!) {
        updateCustomerLang(data: $data)
      }`;
      return abortableMutation(mutation, true) as any;
    },
    checkCompanyCode(): AbortableMutationWithOptionalArgs<boolean, IcheckCompanyCodeArgs> {
      const mutation = graphQlTag`
      mutation checkCompanyCode ($data: String) {
        checkCompanyCode(data: $data)
      }`;
      return abortableMutation(mutation, true) as any;
    },
    createReservation(): FragmentableMutationWithArgs<IReservation[], IcreateReservationArgs> {
      return {
        $fragment: (fragment: string | DocumentNode) => {
          const { isString, isFragment, fragmentName } = guessFragmentType(fragment);
          const mutation = graphQlTag`
      mutation createReservation ($data: GroupedReservationCreationInput!) {
        createReservation(data: $data) {
          ${isString ? fragment : '...' + fragmentName}
        }
      } ${isFragment ? fragment : ''}
      `;

          return abortableMutation(mutation, true) as any;
        }
      };
    },
    updateReservation(): FragmentableMutationWithArgs<IReservation, IupdateReservationArgs> {
      return {
        $fragment: (fragment: string | DocumentNode) => {
          const { isString, isFragment, fragmentName } = guessFragmentType(fragment);
          const mutation = graphQlTag`
      mutation updateReservation ($where: ReservationWhereUniqueInput!,$data: ReservationUpdateInput!,$rejectReasonConstant: String) {
        updateReservation(where: $where,data: $data,rejectReasonConstant: $rejectReasonConstant) {
          ${isString ? fragment : '...' + fragmentName}
        }
      } ${isFragment ? fragment : ''}
      `;

          return abortableMutation(mutation, true) as any;
        }
      };
    },
    cancelReservation(): FragmentableMutationWithArgs<IReservation[], IcancelReservationArgs> {
      return {
        $fragment: (fragment: string | DocumentNode) => {
          const { isString, isFragment, fragmentName } = guessFragmentType(fragment);
          const mutation = graphQlTag`
      mutation cancelReservation ($where: GroupedReservationCancelInput!) {
        cancelReservation(where: $where) {
          ${isString ? fragment : '...' + fragmentName}
        }
      } ${isFragment ? fragment : ''}
      `;

          return abortableMutation(mutation, true) as any;
        }
      };
    },
    updateNurseryConfig(): FragmentableMutationWithArgs<INurseryConfig, IupdateNurseryConfigArgs> {
      return {
        $fragment: (fragment: string | DocumentNode) => {
          const { isString, isFragment, fragmentName } = guessFragmentType(fragment);
          const mutation = graphQlTag`
      mutation updateNurseryConfig ($data: NurseryConfigInput!) {
        updateNurseryConfig(data: $data) {
          ${isString ? fragment : '...' + fragmentName}
        }
      } ${isFragment ? fragment : ''}
      `;

          return abortableMutation(mutation, true) as any;
        }
      };
    },
    updateRegistration(): FragmentableMutationWithArgs<IRegistration, IupdateRegistrationArgs> {
      return {
        $fragment: (fragment: string | DocumentNode) => {
          const { isString, isFragment, fragmentName } = guessFragmentType(fragment);
          const mutation = graphQlTag`
      mutation updateRegistration ($where: RegistrationWhereUniqueInput!,$data: RegistrationUpdateInput!) {
        updateRegistration(where: $where,data: $data) {
          ${isString ? fragment : '...' + fragmentName}
        }
      } ${isFragment ? fragment : ''}
      `;

          return abortableMutation(mutation, true) as any;
        }
      };
    },
    createRegistration(): FragmentableMutationWithArgs<
      IRegistrationCreation,
      IcreateRegistrationArgs
    > {
      return {
        $fragment: (fragment: string | DocumentNode) => {
          const { isString, isFragment, fragmentName } = guessFragmentType(fragment);
          const mutation = graphQlTag`
      mutation createRegistration ($data: RegistrationCreateInput!) {
        createRegistration(data: $data) {
          ${isString ? fragment : '...' + fragmentName}
        }
      } ${isFragment ? fragment : ''}
      `;

          return abortableMutation(mutation, true) as any;
        }
      };
    },
    updateMail(): FragmentableMutationWithArgs<IMail, IupdateMailArgs> {
      return {
        $fragment: (fragment: string | DocumentNode) => {
          const { isString, isFragment, fragmentName } = guessFragmentType(fragment);
          const mutation = graphQlTag`
      mutation updateMail ($where: MailWhereUniqueInput!,$data: MailUpdateInput) {
        updateMail(where: $where,data: $data) {
          ${isString ? fragment : '...' + fragmentName}
        }
      } ${isFragment ? fragment : ''}
      `;

          return abortableMutation(mutation, true) as any;
        }
      };
    },
    upsertFamilyCode(): FragmentableMutationWithArgs<IFamilyCode, IupsertFamilyCodeArgs> {
      return {
        $fragment: (fragment: string | DocumentNode) => {
          const { isString, isFragment, fragmentName } = guessFragmentType(fragment);
          const mutation = graphQlTag`
      mutation upsertFamilyCode ($data: FamilyCodeUpsertInput!) {
        upsertFamilyCode(data: $data) {
          ${isString ? fragment : '...' + fragmentName}
        }
      } ${isFragment ? fragment : ''}
      `;

          return abortableMutation(mutation, true) as any;
        }
      };
    },
    createBilling(): FragmentableMutationWithArgs<IBilling, IcreateBillingArgs> {
      return {
        $fragment: (fragment: string | DocumentNode) => {
          const { isString, isFragment, fragmentName } = guessFragmentType(fragment);
          const mutation = graphQlTag`
      mutation createBilling ($data: CreateBillingInput!) {
        createBilling(data: $data) {
          ${isString ? fragment : '...' + fragmentName}
        }
      } ${isFragment ? fragment : ''}
      `;

          return abortableMutation(mutation, true) as any;
        }
      };
    },
    deleteBilling(): AbortableMutationWithArgs<boolean, IdeleteBillingArgs> {
      const mutation = graphQlTag`
      mutation deleteBilling ($where: WhereUniqueInput!) {
        deleteBilling(where: $where)
      }`;
      return abortableMutation(mutation, true) as any;
    },
    executeCron(): AbortableMutationWithArgs<boolean, IexecuteCronArgs> {
      const mutation = graphQlTag`
      mutation executeCron ($data: CronInput!) {
        executeCron(data: $data)
      }`;
      return abortableMutation(mutation, true) as any;
    },
    updateCommunityNurseryAvailabilities(): AbortableMutationWithArgs<
      boolean,
      IupdateCommunityNurseryAvailabilitiesArgs
    > {
      const mutation = graphQlTag`
      mutation updateCommunityNurseryAvailabilities ($data: CommunityNurseryAvailabilityInput!) {
        updateCommunityNurseryAvailabilities(data: $data)
      }`;
      return abortableMutation(mutation, true) as any;
    },
    updateCommunityNurseryTemplateAvailabilities(): AbortableMutationWithArgs<
      boolean,
      IupdateCommunityNurseryTemplateAvailabilitiesArgs
    > {
      const mutation = graphQlTag`
      mutation updateCommunityNurseryTemplateAvailabilities ($data: CommunityNurseryTemplateAvailabilityInput!) {
        updateCommunityNurseryTemplateAvailabilities(data: $data)
      }`;
      return abortableMutation(mutation, true) as any;
    },
    updateCommunityNurseryZipCodes(): AbortableMutationWithArgs<
      boolean,
      IupdateCommunityNurseryZipCodesArgs
    > {
      const mutation = graphQlTag`
      mutation updateCommunityNurseryZipCodes ($data: CommunityNurseryZipCodeInput!) {
        updateCommunityNurseryZipCodes(data: $data)
      }`;
      return abortableMutation(mutation, true) as any;
    },
    testMutation(): AbortableMutation<boolean> {
      const mutation = graphQlTag`
      mutation testMutation  {
        testMutation
      }`;
      return abortableMutation(mutation, false) as any;
    },
    createOrUpdateChildAdaptationForm(): FragmentableMutationWithArgs<
      IChildAdaptationForm,
      IcreateOrUpdateChildAdaptationFormArgs
    > {
      return {
        $fragment: (fragment: string | DocumentNode) => {
          const { isString, isFragment, fragmentName } = guessFragmentType(fragment);
          const mutation = graphQlTag`
      mutation createOrUpdateChildAdaptationForm ($form: CreateOrUpdateChildAdaptationFormInput!) {
        createOrUpdateChildAdaptationForm(form: $form) {
          ${isString ? fragment : '...' + fragmentName}
        }
      } ${isFragment ? fragment : ''}
      `;

          return abortableMutation(mutation, true) as any;
        }
      };
    },
    updateEcesu(): FragmentableMutationWithArgs<IReceipt, IupdateEcesuArgs> {
      return {
        $fragment: (fragment: string | DocumentNode) => {
          const { isString, isFragment, fragmentName } = guessFragmentType(fragment);
          const mutation = graphQlTag`
      mutation updateEcesu ($data: UpdateEcesuInput!) {
        updateEcesu(data: $data) {
          ${isString ? fragment : '...' + fragmentName}
        }
      } ${isFragment ? fragment : ''}
      `;

          return abortableMutation(mutation, true) as any;
        }
      };
    }
  };
};
